import Home from "src/assets/icons/24/Home";
import ProdutosFinanceiros from "src/assets/icons/24/ProdutosFinanceiros";
import LogoSmall from "src/assets/icons/24/Logo";
import FaleConosco from "src/assets/icons/24/FaleConosco";

export const navigation = [
  {
    icon: <Home className="w-4 h-4" />,
    name: "Home",
    href: "/",
  },
  {
    icon: <ProdutosFinanceiros className="w-4 h-4" />,
    name: "Produtos financeiros",
    href: "/produtos-financeiros",
  },
  {
    icon: <LogoSmall className="w-4 h-auto" />,
    name: "Sobre nós",
    href: "/#sobre-nos",
  },
  {
    icon: <FaleConosco className="w-4 h-auto" />,
    name: "Ajuda",
    href: "/ajuda",
  },
];
