function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <path
          id="Vector"
          d="M13.6065 2.35471C13.6065 1.00854 12.3946 0.736328 11.742 0.736328C9.78803 0.736328 5.36172 2.84694 5.36172 5.20738C5.36172 5.70334 5.54071 6.12844 5.90242 6.5088C6.14853 6.79966 6.59974 6.77729 6.86823 6.53117C7.13671 6.26269 7.13671 5.83385 6.8906 5.56536C6.8011 5.47587 6.71161 5.364 6.71161 5.20738C6.71161 4.08496 9.92227 2.08622 11.742 2.08622C12.1 2.08622 12.2603 2.19809 12.2603 2.35471C12.2603 2.48895 12.1932 2.77981 11.9919 3.20865C10.9813 5.22976 5.76818 10.8009 0.693029 13.9444C0.402168 14.1234 0.312672 14.4627 0.424542 14.7536C0.536411 15.0221 0.760153 15.2272 1.16661 15.1563C2.80737 14.8878 6.55872 14.3695 8.46797 14.3695C8.84832 14.3695 9.14291 14.0786 9.14291 13.6946C9.14291 13.3105 8.85205 13.0196 8.46797 13.0196C7.23367 13.0196 5.48105 13.221 3.59418 13.4932C4.58236 12.8406 5.32443 12.2365 6.08887 11.6063C9.88498 8.39566 13.614 4.30497 13.614 2.35098L13.6065 2.35471Z"
          fill="currentColor"
        />
        <path
          id="Vector_2"
          d="M23.5147 11.1849C23.3357 10.8717 22.9068 10.7822 22.5936 10.9835C20.7291 12.1731 17.7422 13.7915 16.3476 13.7915L18.9317 7.95187C19.066 7.63863 18.9541 7.25454 18.6409 7.09793C18.3277 6.91894 17.9436 7.03081 17.7422 7.32167C17.7198 7.36642 15.833 10.3981 12.2606 13.3626C12.5515 12.4192 13.6776 10.0364 14.7553 7.99289C14.9343 7.67965 14.8224 7.25082 14.4868 7.0942C14.1512 6.91521 13.7447 7.02708 13.5657 7.36269C12.3986 9.70076 10.7578 12.7995 10.7578 14.1233C10.7578 14.7088 11.0934 15.1786 11.6789 15.1786C12.2196 15.1786 12.8684 14.6379 13.7671 13.8549C14.5763 13.1575 15.3184 12.3707 16.012 11.472L15.2028 13.068C15.0685 13.3589 15.0014 13.6535 15.0014 13.922C15.0014 14.5745 15.4973 15.1786 16.2133 15.1786C18.2568 15.1786 22.1648 12.889 23.3133 12.1022C23.6265 11.9009 23.716 11.4944 23.5147 11.1812V11.1849Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Icon;
