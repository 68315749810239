import HeadingSection from "src/components/ui/HeadingSection";
import Safe from "src/assets/videos/safe.mp4";
import Chart from "src/assets/videos/chart.mp4";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";
import { cn } from "src/utils/classes";

const SobreBaaSSection = () => {
  return (
    <section id="sobre-nos" className="bg-white">
      <Container className={"flex flex-col"}>
        <SectionItem
          title={
            "Construímos ecossistemas financeiros customizados e sustentáveis para grandes nichos do mercado."
          }
          description={
            "Somos uma empresa focada em construir soluções financeiras para diversos mercados, resolvendo as principais dores de grandes redes e ainda gerando novas fontes de receitas com produtos customizados. Além de criar o seu próprio ecossistema financeiro, participamos em conjunto da execução e gestão das operações. Em resumo, nós podemos te ajudar a substituir as suas despesas com serviços e produtos financeiros, por receita; em um ecossistema que já existe e é operado por outros fornecedores desses produtos e serviços."
          }
          video={
            <div className="max-w-md hidden lg:block">
              <video src={Safe} autoPlay muted loop />
            </div>
          }
          showButton
        />
        <SectionItem
          className={"flex-row-reverse text-right"}
          textClassName={"max-w-2xl"}
          title={
            "Uma experiência financeira diferente de tudo que você já viu."
          }
          description={
            "Oferecemos serviços de adquirência, mas não somos uma adquirente ou uma operadora de cartão. Oferecemos conta digital e produtos financeiros diferenciados, mas também não somos um banco digital ou um conjunto de APIs. Por fim, nós podemos te oferecer tudo que o mercado financeiro oferece, mas não somos semelhantes a qualquer fintech que você já conheceu!"
          }
          video={
            <div className="max-w-sm hidden lg:block">
              <video src={Chart} autoPlay muted loop />
            </div>
          }
        />
        <div className="max-w-md mx-auto lg:hidden">
          <video src={Safe} autoPlay muted loop controls={false} />
        </div>
      </Container>
    </section>
  );
};

export default SobreBaaSSection;

const SectionItem = ({
  title,
  description,
  textClassName,
  showButton,
  className,
  video,
}) => {
  return (
    <div className={cn("w-full flex items-center justify-between", className)}>
      <div className={cn("max-w-xl flex flex-col", textClassName)}>
        <HeadingSection>{title}</HeadingSection>
        <p className="mt-6 leading-6">{description}</p>
        {showButton && (
          <div className="my-20 self-center md:self-start">
            <Button variant="outline" href={"#vantagens"}>
              Saiba mais sobre nós
            </Button>
          </div>
        )}
      </div>
      {video}
    </div>
  );
};
