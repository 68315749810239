import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";

import { cn } from "src/utils/classes";

export const ActiveLink = ({ href, text }) => {
  const { pathname, hash } = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const fullPath = `${pathname}${hash}`;

    if (href.startsWith("/#") && fullPath.startsWith("/#")) {
      setIsActive(fullPath === href);
    } else if (
      fullPath.startsWith("/#") &&
      !fullPath.startsWith("/#sobre-nos") &&
      href === "/"
    ) {
      setIsActive(href === "/");
    } else {
      setIsActive(fullPath === href);
    }
  }, [pathname, hash, href]);

  return (
    <HashLink
      to={href}
      className={cn(
        "font-medium text-black hover:underline",
        isActive && "text-baas-purple-brand"
      )}
    >
      {text}
    </HashLink>
  );
};
