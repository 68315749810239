import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "src/pages/Home";
import FinancialProducts from "src/pages/FinancialProducts";
import Help from "src/pages/Help";
import Terms from "src/pages/Terms";

import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produtos-financeiros" element={<FinancialProducts />} />
        <Route path="/ajuda" element={<Help />} />
        <Route path="/termos-e-condicoes" element={<Terms />} />
      </Routes>
    </Router>
  );
}

export default App;
