function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6675 3.00687C12.2983 2.63768 11.6997 2.63768 11.3305 3.00687L5.02689 9.31051C4.6577 9.6797 4.6577 10.2783 5.02689 10.6475C5.39607 11.0166 5.99464 11.0166 6.36383 10.6475L11.0537 5.95762V21.3233C11.0537 21.8454 11.4769 22.2686 11.999 22.2686C12.5211 22.2686 12.9444 21.8454 12.9444 21.3233V5.95765L17.6342 10.6475C18.0034 11.0166 18.6019 11.0166 18.9711 10.6475C19.3403 10.2783 19.3403 9.6797 18.9711 9.31051L12.6675 3.00687Z"
        fill="#0BAE74"
      />
    </svg>
  );
}

export default Icon;
