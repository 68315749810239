function Icon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 137 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7444 16.7103C20.6659 16.1305 21.4278 15.3731 22.0214 14.4473C22.8543 13.1428 23.2751 11.6186 23.2751 9.92142C23.2751 6.82624 22.2252 4.43706 20.1564 2.81934C18.1408 1.2437 15.1683 0.444187 11.3319 0.444187H0V34.1031H12.5191C16.1251 34.0704 18.9559 33.2054 20.9361 31.5222C22.9517 29.811 23.9706 27.2722 23.9706 23.976C23.9706 22.0263 23.4745 20.3478 22.4955 18.9872C21.7734 17.982 20.8519 17.2199 19.74 16.7103H19.7444ZM7.42022 19.9363H12.7008C15.3322 19.9784 16.5548 21.2361 16.5548 23.9058C16.5548 25.07 16.2049 25.949 15.4828 26.5943C14.7474 27.2535 13.6842 27.5901 12.3242 27.5901H7.42022V19.9363ZM11.5844 14.0733H7.42022V6.99924H11.3319C12.9533 6.99924 14.1405 7.28912 14.8537 7.85485C15.5315 8.39721 15.8593 9.29023 15.8593 10.59C15.8593 12.0488 15.4163 13.9985 11.5888 14.0686L11.5844 14.0733Z"
        fill="white"
      />
      <path
        d="M46.8781 27.9501V17.4723C46.8781 14.6016 45.9789 12.334 44.2024 10.7303C42.4526 9.14996 40.0737 8.35045 37.1322 8.35045C35.2051 8.35045 33.4376 8.70578 31.8827 9.41178C30.3056 10.1271 29.0608 11.1324 28.188 12.4041C27.3065 13.6899 26.8591 15.1065 26.8591 16.6214V17.1871H34.0445V16.6214C34.0445 15.8827 34.2704 15.3216 34.7311 14.9055C35.214 14.4754 35.9139 14.2556 36.8088 14.2556C37.81 14.2556 38.5409 14.5268 38.9751 15.0645C39.4313 15.6255 39.6617 16.4063 39.6617 17.3788V18.2017H37.3803C33.8806 18.2157 31.1517 18.9498 29.269 20.3852C27.3331 21.8626 26.3496 23.9993 26.3496 26.7392C26.3496 28.9834 27.1559 30.8723 28.7418 32.3451C30.31 33.8039 32.3035 34.5426 34.6735 34.5426C36.8265 34.5426 38.6561 33.8553 40.1313 32.4947C40.2553 32.981 40.4104 33.4111 40.592 33.7898L40.7426 34.0984H47.7863V33.0137L47.7243 32.8875C47.175 31.7981 46.8914 30.1336 46.8781 27.9455V27.9501ZM38.2618 27.9081C37.5796 28.2868 36.8265 28.4785 36.0114 28.4785C35.1963 28.4785 34.6115 28.2727 34.173 27.8473C33.7521 27.4358 33.5439 26.9262 33.5395 26.2903L33.5572 25.9397C33.6192 25.229 33.8274 23.111 37.9163 23.111H39.6661V26.5522C39.3472 27.1179 38.8865 27.5621 38.2574 27.9127L38.2618 27.9081Z"
        fill="white"
      />
      <path
        d="M70.1221 27.9501V17.4723C70.1221 14.6016 69.2228 12.334 67.4464 10.7303C65.6965 9.14996 63.3176 8.35045 60.3761 8.35045C58.4491 8.35045 56.6815 8.70578 55.1266 9.41178C53.5495 10.1271 52.3047 11.1324 51.432 12.4041C50.5504 13.6899 50.103 15.1065 50.103 16.6214V17.1871H57.2884V16.6214C57.2884 15.8827 57.5143 15.3216 57.9751 14.9055C58.4579 14.4754 59.1579 14.2556 60.0527 14.2556C61.0539 14.2556 61.7848 14.5268 62.219 15.0645C62.6753 15.6255 62.9056 16.4063 62.9056 17.3788V18.2017H60.6242C57.1245 18.2157 54.3956 18.9498 52.5129 20.3852C50.5726 21.8626 49.5935 23.9993 49.5935 26.7392C49.5935 28.9834 50.3998 30.8723 51.9857 32.3451C53.5539 33.8039 55.5474 34.5426 57.9175 34.5426C60.0704 34.5426 61.9 33.8553 63.3752 32.4947C63.4993 32.981 63.6543 33.4111 63.8359 33.7898L63.9866 34.0984H71.0302V33.0137L70.9682 32.8875C70.4189 31.7981 70.1354 30.1336 70.1221 27.9455V27.9501ZM61.5058 27.9081C60.8235 28.2868 60.0704 28.4785 59.2553 28.4785C58.4402 28.4785 57.8555 28.2727 57.4169 27.8473C56.996 27.4358 56.7878 26.9262 56.7834 26.2903L56.8011 25.9397C56.8631 25.229 57.0713 23.111 61.1602 23.111H62.9101V26.5522C62.5911 27.1179 62.1304 27.5621 61.5013 27.9127L61.5058 27.9081Z"
        fill="white"
      />
      <path
        d="M97.5794 24.9578C97.5794 22.2788 96.6934 20.0111 94.9391 18.2064C93.2336 16.4531 90.5047 14.9709 86.8234 13.8021C84.9229 13.1943 83.4699 12.5444 82.5086 11.8617C81.6492 11.2539 81.2328 10.5386 81.2328 9.67361C81.2328 8.71981 81.5872 7.97173 82.3137 7.38729C83.0756 6.7748 84.1831 6.46622 85.6096 6.46622C87.036 6.46622 88.2188 6.83558 89.003 7.56496C89.7782 8.28498 90.1547 9.28554 90.1547 10.6181V11.1838H97.575V10.6181C97.575 8.56551 97.0655 6.69999 96.0599 5.08227C95.0543 3.46454 93.6323 2.19748 91.8249 1.31382C90.0396 0.439497 87.9841 0 85.7159 0C83.4478 0 81.41 0.406768 79.5937 1.20628C77.7508 2.01982 76.3155 3.16999 75.3232 4.62407C74.322 6.09218 73.817 7.78471 73.817 9.65491C73.817 13.2457 75.6776 16.1258 79.35 18.2157C80.6569 18.9592 82.4466 19.7213 84.666 20.4787C86.8013 21.2081 88.2853 21.9 89.0783 22.5359C89.7915 23.1063 90.137 23.9152 90.137 24.9999C90.137 25.9771 89.7959 26.7158 89.0871 27.2582C88.3385 27.8332 87.2531 28.1231 85.8665 28.1231C83.9705 28.1231 82.5485 27.7164 81.6359 26.9075C80.741 26.1173 80.3025 24.9344 80.3025 23.3027V22.737H72.8601V23.3027C72.8601 25.5095 73.4094 27.4779 74.4859 29.1611C75.5624 30.8349 77.135 32.1674 79.1684 33.1259C81.1752 34.0704 83.43 34.5473 85.8621 34.5473C89.3795 34.5473 92.228 33.701 94.3322 32.0318C96.4808 30.3253 97.5705 27.9455 97.5705 24.9578H97.5794Z"
        fill="white"
      />
      <path
        d="M117.434 15.546C117.434 12.7688 115.064 12.2124 113.793 12.2124C109.974 12.2124 101.331 16.5653 101.331 21.4278C101.331 22.4471 101.681 23.3261 102.386 24.1162C102.869 24.7194 103.746 24.6726 104.273 24.163C104.8 23.6066 104.8 22.7276 104.317 22.1712C104.14 21.9842 103.967 21.7551 103.967 21.4325C103.967 19.1181 110.24 14.9943 113.797 14.9943C114.497 14.9943 114.807 15.2281 114.807 15.5507C114.807 15.8266 114.675 16.4297 114.28 17.3087C112.305 21.4792 102.124 32.9623 92.2101 39.4472C91.6386 39.8166 91.4659 40.5132 91.6829 41.1163C91.9044 41.6727 92.343 42.0889 93.1315 41.9486C96.3344 41.3922 103.662 40.3262 107.392 40.3262C108.136 40.3262 108.707 39.723 108.707 38.9376C108.707 38.1521 108.136 37.5489 107.392 37.5489C104.977 37.5489 101.557 37.9651 97.8716 38.5214C99.8031 37.1796 101.252 35.9265 102.745 34.6314C110.16 28.0109 117.443 19.581 117.443 15.5507L117.434 15.546Z"
        fill="#F47A20"
      />
      <path
        d="M136.789 33.7431C136.439 33.0932 135.606 32.9108 134.99 33.327C131.349 35.7816 125.51 39.1152 122.79 39.1152L127.836 27.0758C128.097 26.4259 127.88 25.6404 127.264 25.3178C126.649 24.9485 125.904 25.1776 125.51 25.7807C125.466 25.8742 121.78 32.1254 114.803 38.2409C115.374 36.2959 117.567 31.3866 119.676 27.174C120.026 26.5241 119.809 25.6451 119.149 25.3225C118.489 24.9531 117.7 25.1822 117.35 25.8789C115.069 30.6946 111.866 37.0861 111.866 39.8212C111.866 41.0275 112.526 42 113.664 42C114.719 42 115.99 40.8872 117.744 39.2695C119.326 37.8341 120.77 36.2117 122.134 34.3602L120.553 37.6471C120.292 38.2503 120.159 38.8534 120.159 39.4051C120.159 40.747 121.124 42 122.529 42C126.52 42 134.157 37.2778 136.395 35.6554C137.01 35.2392 137.183 34.407 136.789 33.7571V33.7431Z"
        fill="#F47A20"
      />
    </svg>
  );
}

export default Icon;
