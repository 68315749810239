function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.25 10.5H17.75C17.612 10.5 17.5 10.612 17.5 10.75V15.75C17.5 15.888 17.612 16 17.75 16H18.75C19.439 16 20 15.439 20 14.75V11.75C20 11.061 19.439 10.5 18.75 10.5H18.25ZM18.5 17.5H18.75C20.267 17.5 21.5 16.267 21.5 14.75V11.75C21.5 10.3159 20.3981 9.13567 18.9961 9.0109C18.8695 5.12315 15.668 2 11.75 2C7.832 2 4.63045 5.12315 4.50389 9.0109C3.10186 9.13567 2 10.3159 2 11.75V14.75C2 16.267 3.233 17.5 4.75 17.5H5.75C6.715 17.5 7.5 16.715 7.5 15.75V10.75C7.5 9.87146 6.84936 9.14211 6.0046 9.01847C6.12653 5.95441 8.65752 3.5 11.75 3.5C14.8425 3.5 17.3735 5.95441 17.4954 9.01847C16.6506 9.14211 16 9.87146 16 10.75V15.75C16 16.4468 16.4092 17.0497 17 17.331V17.75C17 18.439 16.439 19 15.75 19H14.2291C13.9319 18.2676 13.2129 17.75 12.375 17.75H11.125C10.022 17.75 9.125 18.647 9.125 19.75C9.125 20.853 10.022 21.75 11.125 21.75H12.375C13.2129 21.75 13.9319 21.2324 14.2291 20.5H15.75C17.267 20.5 18.5 19.267 18.5 17.75V17.5ZM12.875 19.75C12.875 19.475 12.651 19.25 12.375 19.25H11.125C10.849 19.25 10.625 19.475 10.625 19.75C10.625 20.025 10.849 20.25 11.125 20.25H12.375C12.651 20.25 12.875 20.025 12.875 19.75ZM5.25 10.5H4.75C4.061 10.5 3.5 11.061 3.5 11.75V14.75C3.5 15.439 4.061 16 4.75 16H5.75C5.888 16 6 15.888 6 15.75V10.75C6 10.612 5.888 10.5 5.75 10.5H5.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
