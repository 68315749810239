import Wrapper from "src/components/ui/Wrapper";
import HeroSection from "src/components/pages/Home/HeroSection";
import SobreBaaSSection from "src/components/pages/Home/SobreBaas";
import Advantages from "src/components/pages/Home/Advantages";
import Implantation from "src/components/pages/Home/Implantation";
import Differences from "src/components/pages/Home/Differences";
import Benefits from "src/components/pages/Home/Benefits";
import Structure from "src/components/pages/Home/Structure";
import Clients from "src/components/pages/Home/Clients";
import Contact from "src/components/pages/Home/Contact";

const Home = () => {
  return (
    <Wrapper>
      <HeroSection />
      <SobreBaaSSection />
      <Advantages />
      <Implantation />
      <Differences />
      <Benefits />
      <Structure />
      <Clients />
      <Contact />
    </Wrapper>
  );
};

export default Home;
