import HeadingSection from "src/components/ui/HeadingSection";
import StructureImg from "src/assets/images/structure.png";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

export default function Structure() {
  return (
    <section className="bg-baas-grey">
      <Container
        className={
          "flex flex-col md:flex-row items-center justify-around gap-10 md:gap-20"
        }
      >
        <div className="max-w-sm lg:max-w-md">
          <img src={StructureImg} alt="Estrutura BaaS" />
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left gap-6">
          <HeadingSection>
            Estrutura de <br />
            ponta a ponta
          </HeadingSection>
          <p className="leading-7 max-w-sm">
            Os serviços disponíveis no mercado na criação de um BaaS pode exigir
            que contrate diversas soluções para concatenar todos os serviços
            necessários. Mas com a BaaS2u é diferente, aqui você tem uma
            estrutura completa.
          </p>
          <Button
            variant="outline"
            className={"w-fit h-fit whitespace-nowrap"}
            href={"/#contato"}
          >
            Fale com a gente
          </Button>
        </div>
      </Container>
    </section>
  );
}
