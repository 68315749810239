import { Container } from "src/components/ui/Container";
import HeadingSection from "src/components/ui/HeadingSection";

export default function TermsAndConditions() {
  return (
    <Container>
      <HeadingSection className="mb-6 lg:mb-10 ">
        Termos e condições de uso
      </HeadingSection>
      <div id="introduction">
        <p>
          Agradecemos por usar nossos produtos e serviços (“Serviços”). Os
          Serviços serão fornecidos pela MULTIPLICAR BAAS S.A., a qual tem com
          sede na R Funchal, Número 411, CONJ 51 CEP 04.551-060, São Paulo – SP,
          devidamente inscrita no CNPJ/MF sob n. 44.391.784/0001-00
          (“MULTIPLICAR”) e, eventualmente, em conjunto com Instituição(ões)
          Parceira(s). Ao se cadastrar ou utilizar um de nossos Serviços, Você
          manifesta sua concordância com estes Termos de Uso e demais Contratos
          anexos que terão sua aplicabilidade dependendo de como Você utiliza
          nosso Site ou Aplicativo. Por isso, leia-os com atenção! Você
          compreende que, a fim de atender às suas necessidades e lhe conferir
          maior segurança, a Plataforma está sujeita a contínuo aprimoramento,
          do mesmo modo que estes Termos, os quais estarão sempre disponíveis em
          nossa Plataforma. Assim, Você entende ser de sua exclusiva
          responsabilidade consultar estes Termos de tempos em tempos, a fim de
          verificar se está de acordo com as suas atualizações
        </p>
        <br />
      </div>

      <div id="clause-1">
        <p>
          <strong>[Cláusula 1ª] DAS DEFINIÇÕES</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            1.1. <strong>A PARTE OU AS PARTES:</strong> Significa, conforme o
            caso, neste CONTRATO, tanto a CONTRATANTE quanto a CONTRATADA quando
            individualmente referidas, ou a CONTRATANTE e a CONTRATADA quando
            conjuntamente referidas;
          </li>
          <li>
            1.2. <strong>SERVIÇOS:</strong> Significa todas os fornecimentos,
            obrigações, tarefas a serem executadas pela CONTRATADA conforme
            especificado no CONTRATO;
          </li>
          <li>
            1.3. <strong>CONTEÚDO:</strong> significa toda e qualquer informação
            disponibilizada pelo ou por meio do Site ou do Aplicativo, tais como
            textos, dados, software, imagens, vídeos, áudios, recursos
            interativos, entre outros;
          </li>
          <li>
            1.4. <strong>USUÁRIO ou VOCÊ:</strong> É qualquer pessoa física
            maior de 18 anos ou jurídica apta a celebrar contratos nos termos da
            Legislação Brasileira que, mediante um cadastro e/ou uma Conta na
            MULTIPLICAR e aceitou o presente Termo de Uso e seus anexos.
          </li>
          <li>
            1.5. <strong>APLICATIVO:</strong> todas as páginas eletrônicas
            disponibilizadas pela MULTIPLICAR, inclusive aplicativos destinados
            a dispositivos móveis e páginas da web, por onde os Você poderá
            acessar os produtos e serviços da MULTIPLICAR ou de seus Parceiros;
          </li>
          <li>
            1.6. <strong>SITE:</strong> significa os endereços eletrônicos
            www.baas2u.com ou qualquer outro que vier a substituí-lo;
          </li>
          <li>
            1.7. <strong>PARCEIRO(S):</strong> Pessoa física ou Pessoa Jurídica
            contratada pela MULTIPLICAR para prestar SERVIÇOS ou oferecer
            Serviços em seu nome, ou ainda, Pessoa física ou Pessoa Jurídica que
            oferece Serviços em nome próprio por meio do Site ou Aplicativo da
            MULTIPLICAR;
          </li>
          <li>
            1.8. <strong>CONTA(S) e/ou CONTA MULTIPLICAR:</strong> Conta de
            pagamento na modalidade pré-paga, ou seja, que depende de aporte
            prévio de recursos, de titularidade do Usuário e utilizada para
            realizar transações de pagamento. Essa Conta é regulamentada pela
            Circular BACEN nº 3.680/2013;
          </li>
          <li>
            1.9. <strong>EMISSOR:</strong> Empresas nacionais ou estrangeiras,
            instituições bancárias ou não, autorizadas pelas INSTITUIDORAS e
            pelo Banco Central do Brasil (BACEN) a emitir e conceder CARTÕES
            para uso no Brasil e/ou no exterior.
          </li>
          <li>
            1.10. <strong>INSTITUIDORAS DO ARRANJO (INSTITUIDORAS):</strong>{" "}
            Empresas que estabelecem o conjunto de regras e procedimentos gerais
            de organização e funcionamento do sistema de CARTÕES e/ou MEIOS DE
            PAGAMENTO, detentoras dos direitos de propriedade e franqueadoras de
            suas marcas e logotipos à CREDENCIADORA e EMISSORES;
          </li>
          <li>
            1.11. <strong>CREDENCIADORA:</strong> A CREDENCIADORA é autorizada a
            prestar serviços de pagamento por meio eletrônico, através de
            arranjos de pagamento que integram o Sistema de Pagamentos
            Brasileiro (SPB)
          </li>
          <li>
            1.12. <strong>SOFTWARE:</strong> significa o conjunto de soluções
            tecnológicas da MULTIPLICAR (de sua propriedade ou licenciadas por
            Parceiros para uso da MULTIPLICAR), para executar os Serviços
            realizados pela MULTIPLICAR.
          </li>
          <li>
            1.13. <strong>TARIFAS:</strong> São os valores cobrados do Usuário a
            título de remuneração em decorrência de cada Serviço prestado pela
            MULTIPLICAR.
          </li>
          <li>
            1.14. <strong>TRANSAÇÕES:</strong> Significa o ato de pagar, de
            aportar, de retirar ou de transferir, no que couber, recursos para
            ou da Conta, para liquidação de obrigações subjacentes entre
            Usuários das Contas ou com terceiros ou, ainda, para a realização de
            transferências entre Contas não vinculadas à liquidação de
            obrigações.
          </li>
          <li>
            1.15. <strong>DADO(S):</strong> significa todo e qualquer dado
            disponibilizado pelo Usuário que, de alguma forma possibilitem sua
            identificação;
          </li>
          <li>
            1.16. <strong>SANÇÕES:</strong> São as restrições ou proibições
            sobre a capacidade de participar de negócios e outras atividades
            econômicas com certos países, regiões, pessoas, entidades e setores
            industriais ou qualquer medida governamental destinada a privar uma
            entidade alvo (incluindo indivíduos, empresas, territórios, países,
            etc.) de ativos financeiros e econômicos, a fim de neutralizar e
            tentar reduzir o comportamento que ameace a segurança nacional ou
            internacional ou viole o direito internacional.
          </li>
          <li>
            1.17. <strong>PIX:</strong> significa o arranjo de pagamentos
            instituído pelo Banco Central do Brasil que disciplina a prestação
            de serviços de pagamento relacionados a transações de pagamentos
            instantâneas e a própria transação de pagamento instantânea no
            âmbito do arranjo. Tal solução de pagamento instantânea do Banco
            Central do Brasil possibilita a realização de transferência e de
            pagamentos pelos e para os Usuários, detentores de Conta
            MULTIPLICAR.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-2">
        <p>
          <strong>[Cláusula 2ª] ACEITE DOS TERMOS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            2.1. Ao utilizar nossos Serviços, Você deverá ler o conteúdo destes
            Termos e, se estiver de acordo com as condições apresentadas,
            manifestar o seu consentimento livre, expresso, informado e
            inequívoco, por meio da seleção do checkbox correspondente à opção
            “Li e concordo com os Termos de Uso e Políticas de Privacidade”.
          </li>
          <li>
            2.2. Tal consentimento poderá ser revogado a qualquer momento, por
            meio de um de nossos Canais de Atendimento. Contudo, ao revogar o
            seu consentimento a este Termo ou seus anexos, Você compreende que
            isto poderá restringir, suspender ou cancelar alguns dos serviços
            ofertados pelo MULTIPLICAR.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-3">
        <p>
          <strong>[Cláusula 3ª] DO CADASTRO E USO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            3.1. Para usar os Serviços ou acessar o Conteúdo, Você precisa: (I)
            ter 18 (dezoito) anos ou mais, porém, a MULTIPLICAR se reserva o
            direito de autorizar ou cadastrar Usuários menores de 18 anos,
            mediante aprovação especial, realizada a único e exclusivo critério
            da MULTIPLICAR sempre que sejam atendidos os requisitos legais
            aplicáveis; (II) ter um RG, RNM (caso o cliente seja estrangeiro
            residente legalmente no Brasil) ou CNH; (III) ter a capacidade de
            celebrar um contrato vinculativo conosco e não ser impedido de
            fazê-lo segundo as leis aplicáveis; (IV) ser residente no Brasil,
            onde os Serviços estão disponíveis; (V) ter um smartphone com
            sistema operacional iOS ou Android, nas versões mínimas exigidas
            pela MULTIPLICAR, com câmera frontal e com acesso à internet banda
            larga (3G, 4G ou 5G) ou outro dispositivo, sistema operacional ou
            conexão que venha a ser aceito pela MULTIPLICAR; e (VI) cumprir com
            eventuais requisitos adicionais exigidos para determinados Serviços,
            conforme especificados nestes Termos de Uso e/ou seus anexos.
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                3.1.1. Você é responsável pela veracidade e atualidade dos dados
                informados. É importante que eles estejam corretos e atualizados
                para que não ocorram problemas de acesso e/ou utilização de
                nossos Serviços por Você. Quaisquer prejuízos havidos em
                decorrência da inclusão de informações inverídicas ou
                desatualizadas por Você, serão de sua única e exclusiva
                responsabilidade.
              </li>
              <li>
                3.1.2. Caso Você altere qualquer um dos seus dados informados,
                Você deverá atualizá-los através do Site e/ou Aplicativo ou
                entrar em contato com um dos nossos Canais de Atendimento.
              </li>
              <li>
                3.1.3. Para garantir a veracidade de suas informações prestadas,
                Você autoriza a MULTIPLICAR a realizar buscas em bureaus
                públicos e privados para a apuração de eventuais dados pessoais
                incorretos, e a solicitar dados pessoais e/ou documentos
                adicionais, se entender pertinente.
              </li>
              <li>
                3.1.4. Caso a MULTIPLICAR verifique a existência de Dados
                informados incorretos e/ou inverídicos, e não seja possível
                regularizá-los, ou, ainda, caso Você se negue a fornecer
                eventuais esclarecimentos e/ou dados pessoais adicionais, se
                solicitados, a MULTIPLICAR se reserva o direito de, sem qualquer
                aviso prévio, cancelar senhas e dispositivos de segurança e
                bloquear o acesso a seus Serviços, respeitadas as condições da
                legislação aplicável e sem prejuízo de outras medidas que
                entender aplicáveis.
              </li>
              <li>
                3.1.5. O seu cadastro inicial permanecerá válido enquanto o
                saldo da sua Conta não ultrapassar o limite de R$ 5.000,00
                (cinco mil reais). Caso você ultrapasse o valore referido a
                depender da natureza jurídica do Serviço da MULTIPLICAR, nós
                poderemos vira a solicitar algumas informações adicionais.
              </li>
            </ol>
          </li>
          <li>
            3.2. Não poderão se cadastrar no Site ou Aplicativo, nem utilizar os
            Serviços da MULTIPLICAR as pessoas físicas ou jurídicas que sejam
            e/ou cujas subsidiárias, afiliadas, diretores, executivos,
            funcionários ou agentes sejam de propriedade ou controladas por
            pessoas que estejam:
            <ol className="flex flex-col gap-2 pl-6">
              <li>3.2.1. Sujeitas às Sanções;</li>
              <li>
                3.2.2. Localizadas, constituídas ou residentes em um país ou
                território que esteja sujeito às Sanções, ou cujo governo esteja
                sujeito às Sanções, incluindo, sem limitação: a região da
                Crimeia, Cuba, Irã, Síria e Coreia do Norte;
              </li>
              <li>
                3.2.3. Incluídas nas Listas Sancionadas do OFAC (Office of
                Foreign Assets Control ou Escritório de Controle de Ativos
                Estrangeiros, dos Estados Unidos da América) e Listas de
                terroristas das Nações Unidas (ONU), bem como em qualquer lista
                nacional de sanções ou restrições.
              </li>
            </ol>
          </li>
          <li>
            3.3. A MULTIPLICAR reconhece a identidade de gênero de pessoas
            travestis e transexuais ou transgêneros. Nesse sentido, a
            MULTIPLICAR realizará a alteração do nome registrado no Cadastro
            para o Nome Social indicado, quando solicitado por Você através dos
            canais oficiais de comunicação com a MULTIPLICAR.
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                3.3.1. Para realizar tal alteração, Você deverá fornecer à
                MULTIPLICAR documento com CPF no qual conste o referido Nome
                Social, nos termos da Instrução Normativa RFB Nº 1718, de 18 de
                julho de 2017. Destaca-se que o Você é a pessoa responsável pela
                solicitação da alteração do nome no Cadastro e pelo uso do Nome
                Social
              </li>
            </ol>
          </li>
          <li>
            3.4. Você declara ser o único responsável pela guarda e sigilo do
            seu nome de usuário (login) e senhas cadastrados no Site ou
            Aplicativo, que são de caráter pessoal e intransferível. Dessa
            forma, Você assume a responsabilidade de nunca compartilhar seus
            dados de acesso com outras pessoas, pois qualquer prejuízo sofrido
            por essa divulgação será de sua exclusiva responsabilidade.
          </li>
          <li>
            3.5. É vedada a utilização do Site e/ou Aplicativo: (i) visando
            causar qualquer dano ou prejuízo à MULTIPLICAR ou a terceiros; (ii)
            para intencionalmente sobrecarregar, inutilizar ou prejudicar o seu
            regular funcionamento; (iii) executar, publicar ou disseminar vírus,
            spyware ou outros programas de computador maliciosos, que possam
            causar danos e/ou; (iv) para quaisquer fins ilícitos, como lavagem
            de dinheiro, financiamento ao terrorismo e outros.
          </li>
          <li>
            3.6. Sem prejuízo de outras medidas cabíveis, a MULTIPLICAR poderá,
            suspender o seu acesso ao Site e/ou Aplicativo, temporária ou
            definitivamente, se: (i) Você descumprir as disposições constantes
            nestes Termos de Uso e seus anexos; (ii) existirem suspeitas de
            prática de atos fraudulentos ou ilegais; (iii) caso não seja
            possível a verificação da sua identidade, ou se os seus Dados
            estiverem incorretos, e/ou (iv) caso seja verificada irregularidade
            de natureza grave nas informações prestadas.
          </li>
          <li>
            3.7. Você poderá, a qualquer momento, excluir sua conta por meio do
            Aplicativo e/ou Canais de Atendimento. Lembre-se apenas que, para
            cumprir com obrigações legais, a MULTIPLICAR guardará algumas
            informações e Dados de acordo com a lei aplicável.
          </li>
          <li>
            3.8. Você declara ser o único responsável pelas operações
            financeiras que realizar por meio do Site e/ou Aplicativo, assumindo
            todos os riscos a estas relacionados, inclusive em caso de eventual
            prejuízo advindos de tais operações;
          </li>
          <li>
            3.9. Você declara ter ciência de que quaisquer informações e/ou
            ofertas apresentadas por meio do Site e/ou Aplicativo poderão ser
            modificadas a qualquer tempo, pela MULTIPLICAR, a seu exclusivo
            critério, sem que nenhuma indenização lhe seja devida em razão
            disto.
          </li>
          <li>
            3.10. Você declara que compreende que o acesso do Site e/ou
            Aplicativo por meio de conexão em redes wi-fi públicas e
            desprotegidas oferece risco às informações armazenadas em seu
            dispositivo móvel. Assim, Você declara estar ciente de que deverá
            assegurar a confiabilidade das redes pelas quais acessará ao Site
            e/ou Aplicativo, sendo de sua exclusiva responsabilidade quaisquer
            danos advindos do uso mediante acesso a redes públicas consideradas
            inseguras, uma vez que a MULTIPLICAR não possui qualquer ingerência
            nas medidas de segurança adotadas por tais redes.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-4">
        <p>
          <strong>[Cláusula 4ª] TERMOS E CONDIÇÕES DE USO ESPECÍFICOS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            4.1. ALÉM DESSES TERMOS DE USO, PARA VOCÊ TER ACESSO A ALGUNS DOS
            SERVIÇOS SEJAM ELES PRESTADOS PELA MULTIPLICAR OU POR NOSSOS
            PARCEIROS, VOCÊ DECLARA E GARANTE QUE LEU, COMPREENDEU E ESTÁ DE
            ACORDO COM O CONTEÚDO DOS CONTRATOS ESPECÍFICOS E/OU ANEXOS LIGADOS
            AO SERVIÇO QUE VOCÊ DESEJA USAR E COMPROMETE-SE A CUMPRI-LOS DE
            FORMA INTEGRAL.
          </li>
          <li>
            4.2. CASO VOCÊ NÃO CONCORDE COM QUALQUER TÓPICO DISPOSTO NOS
            REFERIDOS CONTRATOS (OU NÃO POSSA CUMPRIR COM TAIS TÓPICOS POR
            QUALQUER MOTIVO), ENTÃO, INFELIZMENTE, VOCÊ NÃO PODERÁ USAR OS
            SERVIÇOS OFERECIDOS PELA MULTIPLICAR.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-5">
        <p>
          <strong>[Cláusula 5ª] CONTRATAÇÃO DOS SERVIÇOS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            5.1. Ao utilizar o Site e/ou Aplicativo, Você poderá contratar os
            Serviços ofertados pela MULTIPLICAR e/ou Parceiros.
            Independentemente do aceite dos presentes Termos, caso efetue a
            contratação de Serviços, Você também deverá ler o documento
            correspondente a tal contratação, assinandoo eletronicamente caso
            esteja de acordo com suas condições.
          </li>
          <li>
            Você declara, desde já, que a indicação de seu nome de usuário,
            senha eletrônica, token e/ou seu PIN representam, em conjunto ou
            separadamente, a sua assinatura eletrônica, e reconhece sua validade
            para efeitos de contratação.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-6">
        <p>
          <strong>[Cláusula 6ª] SOLICITAÇÃO DE CARTÃO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            6.1. Ao abrir sua Conta junto a MULTIPLICAR, será possível solicitar
            a emissão de cartão magnético múltiplo (“Cartão”) que poderá ter as
            funções débito e/ou crédito habilitada(s).
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                6.1.1. A função crédito somente será habilitada mediante prévia
                autorização da MULTIPLICAR e do Emissor. Caso o crédito não seja
                aprovado, Você somente poderá utilizar o seu Cartão na função
                débito.
              </li>
            </ol>
          </li>
          <li>
            6.2. Para Você saber tudo que pode fazer com o Cartão, Você deverá
            ler o contrato aplicável na aba “documentos” do SITE, e solicitar a
            contratação do Serviço através da manifestação do seu consentimento
            livre, expresso, informado e inequívoco.
          </li>
          <li>
            6.3. Medidas de Segurança:
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                6.3.1. Ao receber o seu Cartão, confira seus dados pessoais nele
                contidos. Por medida de segurança, a MULTIPLICAR enviará o
                Cartão Físico sempre bloqueado para utilização. O desbloqueio
                deverá ser realizado por meio dos Canais de Atendimento
                indicados no Site e/ou Aplicativo;
              </li>
              <li>
                6.3.2. Você é responsável pelo uso e guarda do seu Cartão, assim
                como respectiva senha;
              </li>
              <li>
                6.3.3. Como medida de segurança, você deve: (I) guardar o seu
                Cartão em local seguro, nunca permitindo o uso por terceiros;
                (II) memorizar sua senha e mantê-la em sigilo, não a informando
                para terceiros; (III) nunca anotar ou guardar sua senha junto
                com seu Cartão; e (IV) nunca divulgar seu Cartão em locais
                públicos, principalmente em mídias sociais;
              </li>
              <li>
                6.3.4. Adotaremos também como medida de segurança, a solicitação
                de novos documentos ou fotos para comprovação da sua identidade
                durante o uso do Site e/ou Aplicativo, caso no qual serão
                tratados em conformidade com as normas e leis aplicáveis;
              </li>
              <li>
                Adotaremos ainda como possível medida de segurança, o bloquear
                do seu Cartão preventivamente caso verifiquemos operações fora
                do seu padrão de uso. VOCÊ PODE EVITAR ESSE TIPO DE BLOQUEIO,
                AVISANDO A MULTIPLICAR ANTES DE REALIZAR OPERAÇÕES FORA DO SEU
                PADRÃO, POR EXEMPLO, EM VIAGENS AO EXTERIOR E COMPRAS COM ALTO.
              </li>
            </ol>
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-7">
        <p>
          <strong>[Cláusula 6ª] SOLICITAÇÃO DE CARTÃO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            7.1. <strong>Regras gerais</strong>
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                7.1.1. A MULTIPLICAR reserva-se o direito de não processar as
                Transações que: (i) contenham dados incompletos, contraditórios
                ou inválidos; (ii) a MULTIPLICAR, a seu exclusivo critério,
                considere envolver algum risco; (ii) estejam em desacordo aos
                presentes Termos de Uso e/ou seus Anexos; (iv) não sejam
                autorizadas pelo Banco ou pela Credenciadora ou pelo Emissor de
                cartões de crédito ou débito.
              </li>
              <li>
                7.1.2. A MULTIPLICAR não será responsável por ordens, instruções
                e/ou Transações equivocadas ou incompletas causadas por: (i)
                introdução errônea do e-mail; (ii) informação errônea ou
                incompleta do Usuário e/ou terceiro recebedor, da operação de
                pagamento e/ou quaisquer outros dados fornecidos pelo Usuário
                pagador; e/ou (iii) objeto ilícito ou em desacordo com estes
                Termos e seus anexos.
              </li>
            </ol>
          </li>
          <li>
            7.2. <strong>Aporte de recursos na Conta</strong>
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                7.2.1. Para a realização de Transações, Você deverá realizar o
                aporte prévio de Moeda Eletrônica na Conta, por meio de:
                <ol className="flex flex-col gap-2 pl-6">
                  <li>7.2.1.1. Transferência entre Contas MULTIPLICAR;</li>
                  <li>
                    7.2.1.2. Transferência Eletrônica Disponível (TED) ou Pix de
                    uma Conta Bancária para sua Conta MULTIPLICAR;
                  </li>
                  <li>
                    Emissão de boleto, em nome próprio para aporte na sua Conta;
                  </li>
                  <li>
                    Os recursos provenientes de portabilidade salarial também
                    são considerados aportes de Moeda Eletrônica na Conta.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            7.3. <strong>Utilização dos recursos em Conta</strong>
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                7.3.1. Descrevemos a baixo, de forma resumida as principais
                características dos nossos Serviços, para que você possa
                desfrutá-los da melhor forma possível, com apenas poucos
                comandos diretamente no Aplicativo e/ou Site:
                <ol className="flex flex-col gap-2 pl-6">
                  <li>
                    7.3.1.1. <u>Transferência entre Contas</u>: este produto
                    permitirá a transferência de recursos de um Usuário para
                    outro Usuário, ambos clientes da MULTIPLICAR;
                  </li>
                  <li>
                    7.3.1.2.{" "}
                    <u>
                      Transferências para contas em outras instituições
                      financeiras via TED
                    </u>
                    : este produto contempla a transferência de recursos de um
                    Usuário para um terceiro (Usuário ou não) titular de conta
                    corrente junto à instituição financeira de sua escolha,
                    desde que respeitados os horários limites estabelecidos pela
                    Instituição Parceira;
                  </li>
                  <li>
                    7.3.1.3. <u>Transferências via PIX</u>: A MULTIPLICAR é
                    participante indireta do PIX, logo suas transações são
                    liquidadas por intermédio de um participante direto, ou seja
                    uma Parceira da MULTIPLICAR. Este produto contempla a
                    transferência de recursos via arranjo de pagamentos
                    instantâneos, na qual Você pode atuar como pagador ou
                    recebedor dos recursos transferidos via o arranjo de
                    pagamentos instantâneos do Banco Central do Brasil, estando
                    disponível 24 (vinte e quatro) horas por dia, todos os dias
                    do ano, incluindo finais de semana e feriados. Esse serviço
                    está sujeito à confirmação, autenticação e autorização da
                    transação por parte da instituição junto da qual o recebedor
                    possui uma conta transacional.
                  </li>
                  <li>
                    7.3.1.4. <u>Emissão de boleto</u>: prestação de serviços de
                    emissão de boletos de pagamento, podendo ser boleto de
                    cobrança, boleto de proposta ou boleto de depósito;
                  </li>
                  <li>
                    7.3.1.5. <u>Pagamento de boletos e de contas de consumo</u>:
                    este produto contempla pagamento de boletos, ou qualquer
                    outra conta de consumo que o Usuário possua, incluindo, mas
                    não se limitando a, recarga de celulares, guias,
                    contribuições estatais e/ou federais;
                  </li>
                  <li>
                    7.3.1.6. <u>Saque</u>: este produto contempla a retirada de
                    recursos existentes na Conta do Usuário em um caixa
                    eletrônico compatível e disponível para a bandeira dos
                    Cartões;
                  </li>
                  <li>
                    7.3.1.7. <u>Recebimento de transferências</u>: este produto
                    contempla o recebimento de valores oriundos de outras
                    contas, tanto do próprio Usuário, quanto de conta de
                    terceiros;
                  </li>
                  <li>
                    7.3.1.8. <u>Produtos de crédito</u>: oferta de produtos de
                    crédito à base de clientes (i.e., CDC, consignado ou
                    rotativo por meio de Cartão Pós-Pago) a serem contratados
                    junto à Instituição Parceira e mediante aprovação prévia;
                  </li>
                  <li>
                    7.3.1.9. <u>Cartões</u>: são utilizados para realizar
                    Transações de pagamento através do Cartão Físico (Cartão
                    Pré-Pago), no Brasil ou no exterior, de acordo com as regras
                    definidas pelo Instituidor do arranjo de pagamento dos
                    Cartões;
                  </li>
                </ol>
              </li>
              <li>
                7.3.2. Você deve lembra que cada um destes serviços tem regras e
                tarifas próprias para utilização dos mesmos, as quais são parte
                integrante deste Termo Condições de Uso, e estão disponíveis no
                Site e/ou Aplicativo da MULTIPLICAR.
              </li>
            </ol>
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-8">
        <p>
          <strong>[Cláusula 8ª] DA LICENÇA DE USO DO SOFTWARE</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            8.1. A MULTIPLICAR, por meio destes Termos de Uso, concede a Você
            uma licença pessoal, limitada, temporária, mundial, revogável, não
            exclusiva e intransferível de uso do Software, por prazo
            indeterminado, sendo reservado a MULTIPLICAR o direito de modificar,
            suspender ou descontinuar o acesso ao Site e/ou Aplicativo, a
            qualquer tempo, mediante aviso prévio.
          </li>
          <li>
            8.2. É vedado a Você utilizar e nem permitir o uso do Software para
            qualquer outra finalidade não expressamente prevista nestes Termos
            de Uso, desta forma, não será permitido a Você, copiar, modificar,
            distribuir, vender, alugar, compartilhar ou, ainda, ceder, a
            qualquer título, os Serviços disponibilizados pela MULTIPLICAR e
            viabilizados pelo Software.
          </li>
          <li>
            8.3. A utilização do Software por Você possui caráter pessoal e
            intransferível e está autorizada unicamente para fins lícitos
            relacionados ao propósito a que o Site e/ou Aplicativo se destinam,
            de acordo com estes Termos de Uso.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-9">
        <p>
          <strong>[Cláusula 9ª] APLICAÇÕES DE TERCEIROS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            9.1. Os Serviços oferecidos pela MULTIPLICAR poderão estar
            integrados com aplicativos, sites e serviços de terceiros
            (“Aplicativos de Terceiros”) para fins de disponibilização a Você
            dos Serviços ofertados no Site e/ou Aplicativo. Desta forma Você
            está ciente e concorda que a existência destes Aplicativos de
            Terceiros não representa um endosso ou patrocínio por parte da
            MULTIPLICAR a estes terceiros e reconhece que os Aplicativos de
            Terceiros estarão sujeitos aos termos de uso, políticas e anexos
            próprios, os quais deverão ser verificados por Você.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-10">
        <p>
          <strong>[Cláusula 10ª] PROPRIEDADE INTELECTUAL</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            10.1. Você compreende que cada um dos textos, imagens, fotografias,
            projetos, dados, vídeos, ilustrações, ícones, tecnologias, links e
            demais conteúdos audiovisuais ou sonoros, incluindo o software, o
            Site e o Aplicativo, desenhos gráficos e/ou códigos fonte, dentre
            outros, são de propriedade exclusiva da MULTIPLICAR ou de terceiros
            que tenha autorizado sua utilização pela MULTIPLICAR, estando
            protegidos pelas leis e tratados internacionais, sendo vedada sua
            cópia, reprodução, ou qualquer outro tipo de utilização, ficando os
            infratores sujeitos às sanções civis e criminais correspondentes.
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                10.1.1.As marcas, nomes comerciais ou logotipos de qualquer
                espécie apresentados por meio do Site e/ou Aplicativo são de
                propriedade da MULTIPLICAR ou de terceiros que tenham permitido
                o seu uso, de modo que a utilização do Site e/ou Aplicativo não
                significa autorização para que Você possa citar tais marcas,
                nomes comerciais e logotipos.
              </li>
              <li>
                10.1.2.Caso Você identifique a violação de direitos relativos à
                propriedade intelectual, poderá enviar uma denúncia por meio de
                um de nossos Canais de Atendimento, para que possamos tomar as
                medias cabíveis.
              </li>
            </ol>
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-11">
        <p>
          <strong>[Cláusula 11ª] ISENÇÕES DE RESPONSABILIDADE</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            11.1. Por quaisquer indisponibilidades, erros e/ou falhas no Site
            e/ou Aplicativo;
          </li>
          <li>
            11.2. Por eventuais erros e/ou inconsistências na transmissão de
            dados da rede, bem como relacionados à qualidade ou disponibilidade
            da conexão de internet capazes de obstar o adequado recebimento de
            informações pela MULTIPLICAR ou por Você;
          </li>
          <li>
            11.3. Por dados desatualizados, incompletos e/ou inverídicos
            eventualmente apresentados por meio do Site e/ou Aplicativo;
          </li>
          <li>
            11.4. Por eventuais danos resultantes do uso do Software, Site e/ou
            Aplicativo em desacordo com estes Termos;
          </li>
          <li>
            11.5. Por qualquer desvalorização que possa vir a ocorrer em
            qualquer transação/aplicação realizada por Você, bem como qualquer
            prejuízo que venha a suportar por essa razão;
          </li>
          <li>
            11.6. Pelos danos e prejuízos de toda natureza decorrentes do
            conhecimento que terceiros não autorizados possam ter a dados
            disponibilizados por meio do Site e/ou Aplicativo, em decorrência de
            falha exclusivamente relacionada a Você ou a terceiros que fujam ao
            controle razoável da MULTIPLICAR;
          </li>
          <li>
            11.7. Pela presença de vírus ou demais elementos nocivos no Site
            e/ou Aplicativo, capazes de causar alterações em seus sistemas
            informáticos (software e hardware) ou em documentos eletrônicos
            armazenados em seus sistemas informáticos.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-12">
        <p>
          <strong>[Cláusula 12ª] TARIFAS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            12.1. Você declara-se ciente de que a MULTIPLICAR cobrará Tarifas
            pela realização dos Serviços, mediante débito na sua Conta, sendo
            que tais Tarifas estarão disponíveis para consulta no Aplicativo
            e/ou Site.
          </li>
          <li>
            12.2. Você declara-se ciente de que a MULTIPLICAR cobrará a Tarifa
            devida pela realização de um Serviço, mesmo que posteriormente
            cancelado em decorrência de erro praticado por Você.
          </li>
          <li>
            12.3. Caso a MULTIPLICAR venha a disponibilizar novos serviços,
            poderá instituir remuneração pelos Serviços que vierem a ser
            solicitados por Você, conforme valores, termos e condições que
            vierem a ser previamente informados, de acordo com a regulamentação
            aplicável.
          </li>
          <li>
            12.4. Você declara-se ciente de que os contratos específicos dos
            Serviços contratados, poderão prever formas e regras especificas
            aplicadas a remuneração dos Serviços contratados.
          </li>
          <li>
            12.5. Você concorda e autoriza que todas as eventuais comunicações e
            notificações feitas pela MULTIPLICAR relacionadas às Tarifas,
            inclusive para fins de constituição de mora ou alteração do valor
            das Tarifas, sejam efetuadas por telefone, ou por escrito, por
            correspondência, por meio de e-mail, SMS, Whatsapp, e/ou outro meio
            eletrônico disponível, de acordo com os dados informados por Você,
            no momento do seu cadastro da MULTIPLICAR, sem prejuízo do eventual
            envio de comunicações por carta, a exclusivo critério da
            MULTIPLICAR.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-13">
        <p>
          <strong>
            [Cláusula 13ª] SISTEMA DE INFORMAÇÕES DE CRÉDITO DO BANCO CENTRAL DO
            BRASIL (“SCR”)
          </strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            13.1. Ao aceitar estes Termos, em observância à regulamentação
            expedida pelo Banco Central (“BACEN”), Você autoriza a MULTIPLICAR
            a:
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                13.1.1.Registrar no SCR quaisquer débitos e responsabilidades
                decorrentes de seu relacionamento com a MULTIPLICAR;{" "}
              </li>
              <li>
                13.1.2.Consultar as informações existentes a seu respeito no
                SCR.
              </li>
            </ol>
          </li>
          <li>
            13.2. Você se declara ciente de que o SCR tem por finalidade
            fornecer ao BACEN informações para fins de supervisão do risco de
            crédito a que estão expostas as instituições financeiras, bem como
            propiciar o intercâmbio de informações entre estas instituições com
            o objetivo de subsidiar decisões de crédito e de negócios, e a
            consequente concessão de crédito e/ou oferta de outros produtos e
            serviços, conforme o caso.
          </li>
          <li>
            13.3. Caso Você entenda serem necessárias correções, exclusões e/ou
            manifestações de discordância com relação às informações constantes
            no SCR, deverá encaminhar requerimento escrito e fundamentado ou,
            quando for o caso, a respectiva decisão judicial, à Central de
            Atendimento ao Público do BACEN (ou à instituição responsável pela
            remessa de tais informações).
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-14">
        <p>
          <strong>
            [Cláusula 14ª] CONSULTA DE DADOS RELACIONADOS A RECEBÍVEIS DE CARTÃO
          </strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            14.1. Nos termos da regulamentação aplicável, incluindo a Instrução
            Normativa BCB nº 63/2021, a Circular BCB nº 3.952/2019 e a Convenção
            entre Entidades Registradoras aprovada pelo Banco Central do Brasil,
            Você autoriza expressamente, de forma livre e informada, a
            MULTIPLICAR a:
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                14.1.1. Consultar as bases de dados mantidas pelo Bacen para
                obter informações a seu respeito relacionadas (a) ao valor total
                agregado dos recebíveis de arranjos de pagamento liquidados de
                forma centralizada, no período de 12 (doze) meses anteriores à
                data da consulta, em sistema de compensação e de liquidação
                autorizado a funcionar pelo Bacen, (b) à quantidade de meses em
                que o valor das liquidações registradas nessa base de dados do
                Bacen foi igual a zero, e (c) aos arranjos de pagamentos que
                constituíram o valor calculado no item “a”;
              </li>
              <li>
                14.1.2. Ter acesso às suas informações relacionadas a agendas de
                recebíveis mantidas junto aos sistemas de registros mantidos por
                entidades autorizadas a realizar a atividade de registro de
                ativos financeiros, incluindo, mas não se limitando à CERC
                Central de Recebíveis e à Câmara Interbancária de Pagamentos –
                CIP (“Registradoras”), autorizando assim a consulta, coleta,
                exame, uso, armazenamento e tratamento dessas informações; e
              </li>
              <li>
                14.1.3. Enviar toda e qualquer informação necessária às
                Registradoras, para fins de registro dos recebíveis
                (constituídos e a constituir), bem como para o registro de toda
                e qualquer ocorrência a eles relacionada, incluindo troca de
                titularidade (definitiva ou não), alteração de domicílio
                bancário para fins de liquidação financeira, bem como
                constituição de ônus e gravames decorrentes de operações de
                desconto, antecipação e/ou empréstimo com garantias de tais
                recebíveis.
              </li>
              <li>
                14.1.4. Você concorda e autoriza expressamente, de forma livre e
                informada, que a MULTIPLICAR compartilhe as informações obtidas
                nos termos desta Cláusula, itens (14.1.1) e (14.1.2) acima com
                suas Parceiras;
              </li>
              <li>
                14.1.5. Você está ciente e concorda que a coleta, exame, uso,
                armazenamento e tratamento das informações mencionadas nos itens
                (14.1.1) e (14.1.2) desta Cláusula, bem como seu
                compartilhamento, nos termos desta cláusula item 14.1.4, serão
                realizados para: (i) análise de seu risco de crédito; (ii)
                oferta, pela MULTIPLICAR e/ou Parceiras a Você, de forma
                individualizada, personalizada e adequada, considerando seu
                perfil, hábitos, histórico e necessidades, de produtos e
                serviços que envolvam recebíveis; (iii) desenvolver, manter e
                aperfeiçoar os recursos e funcionalidades dos produtos e
                serviços que envolvam os recebíveis pela MULTIPLICAR e/ou
                Parceiras; e (v) que a MULTIPLICAR MULTIPLICAR e/ou Parceiras
                possam melhorar a experiência com relação aos produtos e/ou
                serviços que envolvam tais recebíveis.
              </li>
            </ol>
          </li>
          <li>
            14.2. Você poderá revogar quaisquer das autorizações desta Cláusula
            a qualquer momento, por meio dos Canais de Atendimento, exceto com
            relação a recebíveis de operações que estejam em vigor. Ao revogar
            uma ou mais das autorizações acima, Você está ciente e concorda que
            a contratação de produtos e serviços envolvendo recebíveis de cartão
            poderá ficar prejudicada, de forma que tais produtos e serviços
            poderão ficar indisponíveis a Você.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-15">
        <p>
          <strong>[Cláusula 15ª] CADASTRO POSITIVO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            15.1. De acordo com a Lei Complementar nº 166/2019, a MULTIPLICAR
            possui como obrigação fornecer dados financeiros e de pagamentos
            relativos a operações de crédito e obrigações de pagamento
            adimplidas ou em andamento a gestores de bancos de dados para
            formação de histórico de crédito. Entretanto, Você pode requerer a
            exclusão de seu cadastro por meio de nossos Canais de Atendimento,
            indicados neste Termo, caso entenda pertinente.
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                15.1.1.POR MEIO DO ACEITE AOS PRESENTES TERMOS, VOCÊ AUTORIZA OS
                GESTORES DE BANCO DE DADOS DE QUE TRATA A LEI Nº 12.414/2011, A
                DISPONIBILIZAREM A MULTIPLICAR O SEU HISTÓRICO DE CRÉDITO, O
                QUAL ABRANGERÁ OS DADOS FINANCEIROS E DE PAGAMENTOS RELATIVOS ÀS
                OPERAÇÕES DE CRÉDITO E OBRIGAÇÕES DE PAGAMENTO ADIMPLIDAS EM
                SEUS RESPECTIVOS VENCIMENTOS E AQUELAS A VENCER, COM A
                FINALIDADE ÚNICA E EXCLUSIVA DE SUBSIDIAR A ANÁLISE E A EVENTUAL
                CONCESSÃO DE CRÉDITO, A VENDA A PRAZO OU OUTRAS TRANSAÇÕES
                COMERCIAIS E EMPRESARIAIS QUE IMPLIQUEM RISCO FINANCEIRO. VOCÊ
                SE DECLARA CIENTE DE QUE ESTA AUTORIZAÇÃO TEM VALIDADE POR TEMPO
                INDETERMINADO, LIMITADO AO PERÍODO DE DURAÇÃO DO RELACIONAMENTO
                CONTRATUAL ESTABELECIDO ENTRE VOCÊ E A MULTIPLICAR. VOCÊ AINDA
                SE DECLARA CIENTE DE QUE PODERÁ REVOGAR, A QUALQUER TEMPO, ESTA
                AUTORIZAÇÃO, PERANTE O GESTOR DE BANCO DE DADOS, BEM COMO
                SOLICITAR A MULTIPLICAR A REVISÃO DE DECISÃO REALIZADA
                EXCLUSIVAMENTE POR MEIOS AUTOMATIZADOS, POR MEIO DOS CANAIS DE
                ATENDIMENTO INDICADOS NESTE TERMO.
              </li>
            </ol>
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-16">
        <p>
          <strong>[Cláusula 16ª] Obrigações da MULTIPLICAR</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            16.1. A MULTIPLICAR se obriga a seguir as instruções recebidas por
            Você para efetivar as Transações. Ao se cadastrar na MULTIPLICAR e
            manter uma Conta ativa, Você concorda, aceita e autoriza o
            recebimento de dinheiro que outros Usuários oportunamente enviem à
            sua Conta e que sejam debitadas as Tarifas de Serviço da MULTIPLICAR
            aplicáveis.
          </li>
          <li>
            16.2. A MULTIPLICAR poderá determinar um valor máximo para as
            Transações, que poderá variar de acordo com o método de pagamento
            escolhido, tipo de Transação ou outro critério definido pela
            MULTIPLICAR. Estes critérios poderão ser modificados a qualquer
            momento pela MULTIPLICAR, sendo suficiente sua publicação no Site
            e/ou Aplicativo para entrada em vigor.
          </li>
          <li>
            16.3. A MULTIPLICAR envidará os melhores esforços para assegurar o
            cumprimento dos prazos indicados nestes Termos de Uso. Não obstante,
            Você está ciente que, por determinados fatores externos que fujam ao
            controle da MULTIPLICAR ou de Parceiros, poderão ocorrer atrasos,
            motivo pelo qual Você exime a MULTIPLICAR de toda e qualquer
            responsabilidade decorrente de eventuais inconvenientes ou
            prejuízos.
          </li>
          <li>
            16.4. Você reconhece estar sujeito aos riscos da instabilidade da
            rede mundial de computadores, sendo que a MULTIPLICAR não poderá ser
            responsabilizado por eventuais falhas na prestação dos serviços
            decorrentes de interrupções ou atrasos em virtude de falhas na rede
            mundial de computadores que possam impedir ou prejudicar o envio e
            recebimento de informações.
          </li>
          <li>
            16.5. A MULTIPLICAR mantém os recursos correspondentes aos saldos
            disponíveis de Moeda Eletrônica das Contas dos seus Usuários
            correspondentes em reais de acordo com a legislação aplicável, sendo
            que tais recursos constituem patrimônio separado e não se confundem
            com os recursos da MULTIPLICAR. Portanto, os seus recursos, (i) não
            respondem direta ou indiretamente por nenhuma obrigação da
            MULTIPLICAR nem podem ser objeto de arresto, sequestro, busca e
            apreensão ou qualquer outro ato de constrição judicial em função de
            débitos de responsabilidade da MULTIPLICAR; (ii) não compõem o ativo
            da MULTIPLICAR, para efeito de falência ou liquidação judicial ou
            extrajudicial; e (iii) não podem ser dados em garantia de débitos
            assumidos pela MULTIPLICAR.
          </li>
          <li>
            16.6. A MULTIPLICAR não será responsável por eventual Transação que
            não seja efetivada em decorrência da intervenção pelo Banco Central
            ou da insolvência de qualquer Banco, Instituição Financeira.
          </li>
          <li>
            16.7. A MULTIPLICAR também não poderá ser responsabilizada por
            ordens de pagamento não processadas ou recusadas, contas bancárias
            de terceiros suspensas ou canceladas.
          </li>
          <li>
            16.8. Nos termos da regulamentação em vigor, a MULTIPLICAR (ou
            terceiros autorizados por ele) irá(ão): (i) realizar o registro, em
            sistema de registro devidamente autorizado a operar pelo Banco
            Central do Brasil, das Unidades de Recebíveis pertencentes a cada um
            dos Usuários informando o seu valor e (ii) realizar a atualização
            dos valores das Unidades de Recebíveis junto ao sistema de registro,
            devendo contemplar a adição dos valores de recebíveis constituídos
            após a data de registro e ser efetuada até o dia útil subsequente ao
            da realização das transações comerciais subjacentes.
          </li>
          <li>
            16.9. A MULTIPLICAR irá realizar a liquidação financeira das
            unidades de recebíveis que sejam objeto de registro em conformidade
            com as informações sobre posse ou titularidade efetiva ou fiduciária
            dessas unidades e de suas respectivas instituições domicílio,
            disponibilizadas pelos sistemas de registro.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-17">
        <p>
          <strong>[Cláusula 17ª] SOLUÇÃO DE CONTROVÉRSIAS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            17.1. A MULTIPLICAR disponibiliza a Você, uma estrutura para o
            tratamento de reclamações e uma equipe especializada para mediar as
            controvérsias entre a MULTIPLICAR e Você, a fim de evitar a
            judicialização de controvérsias que eventualmente surjam.
          </li>
          <li>
            17.2. Contudo caso as controvérsias não sejam resolvidas, Você
            poderá buscar a solução das mesmas por meio dos serviços, mantidos
            pela Secretaria Nacional do Consumidor (SENACON), do Ministério da
            Justiça e Segurança Pública, em www.consumidor.gov.br, que são
            disponibilizados gratuitamente aos consumidores de todo o país com o
            objetivo de prevenir e reduzir a quantidade de controvérsias
            judicializadas.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-18">
        <p>
          <strong>[Cláusula 18ª] RENÚNCIA A AÇÃO COLETIVA</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            18.1. Quando permitido pela lei aplicável, Você e a MULTIPLICAR
            concordam que cada um só poderá mover ações um contra o outro apenas
            em sua capacidade individual e não como um autor ou membro de classe
            em qualquer ação coletiva ou representativa. A menos que Você e a
            MULTIPLICAR concordem, nenhum árbitro ou juiz poderá consolidar
            reivindicações de mais de uma pessoa ou presidir sobre qualquer
            forma processo representativo ou de classe.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-19">
        <p>
          <strong>[Cláusula 19ª] ANEXOS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            19.1. Formam parte integrante e inseparável destes Termos de Uso e a
            ele se integram por referência os documentos disponibilizados na aba
            “documentos” do Site.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-20">
        <p>
          <strong>[Cláusula 20ª] DISPOSIÇÕES GERAIS</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            20.1. Sem prejuízo das demais disposições destes Termos de Uso, Você
            declara e garante que as Transações por realizadas no Site e/ou
            Aplicativo não serão utilizadas para, mas sem limitação a: (i)
            Infração a legislação em vigor; (ii) Exploração de jogos de azar,
            apostas, etc.; (iii) Atividades de organizações políticas, salvo as
            autorizadas por lei; (iv) Exploração de máquinas de serviços
            pessoais acionadas por moeda; (v) Oferecimento de cartões de
            descontos; (vi) Tráfico de armas, de pessoas, de animais, etc.;
            (vii) Lavagem de dinheiro; (viii) Terrorismo; (ix) Pornografia,
            prostituição ou pedofilia; e/ou (x) Qualquer tipo de atividade que
            possa ser considerada fraudulenta ou ilegal.
          </li>
          <li>
            20.2. Você não utilizará o Site e/ou Aplicativo para efetuar
            qualquer operação que se possa considerar ou interpretar como
            adiantamento de dinheiro, autofinanciamento, lavagem de dinheiro,
            empréstimo ou outras formas semelhantes como definido em Lei, bem
            como toda e qualquer atividade que não esteja em consonância com a
            legislação brasileira.
          </li>
          <li>
            20.3. Você declara, para todos os devidos fins e efeitos, que as
            atividades por desenvolvidas por Você ao utilizar o Site e/ou
            Aplicativo são realizadas em estrita observância à legislação
            trabalhista relativa à saúde e/ou à segurança ocupacional, inclusive
            quanto ao trabalho ilegal, escravo e/ou infantil e/ou de silvícolas,
            direta ou indiretamente, e quanto a práticas discriminatórias; bem
            como observam todas as disposições legais e regulamentares
            aplicáveis, inclusive quanto à Política Nacional de meio Ambiente e
            de Crimes Ambientais, e aquelas emanadas nas esferas federal,
            estadual e/ou municipal.
          </li>
          <li>
            20.4. Você declara, ainda, que cumpre e faz cumprir, bem como suas
            afiliadas, acionistas, conselheiros, diretores, funcionários,
            agentes e/ou eventuais subcontratados (“Pessoas Relacionadas”),
            todas as leis, regras, regulamentos e normas aplicáveis, emitidos
            por qualquer jurisdição aplicável ao Usuário, que versam sobre atos
            de corrupção, suborno e atos lesivos contra a administração pública,
            incluindo, mas não se limitando a Lei nº 12.846/13 (“Leis
            Anticorrupção”); na medida em que (i) mantém políticas e
            procedimentos internos que assegurem integral cumprimento das Leis
            Anticorrupção; (ii) dá pleno conhecimento das Leis Anticorrupção a
            todos os profissionais que venham a ser relacionar com Você,
            conforme aplicável; (iii) abstémse de praticar quaisquer atos
            estabelecidos nas Leis Anticorrupção, no seu interesse ou para seu
            benefício, exclusivo ou não; e (iv) caso tenha conhecimento de
            qualquer ato ou fato que viole as Leis Anticorrupção, comunicará
            imediatamente a MULTIPLICAR, que poderá tomar todas as providências
            que entender necessárias.
          </li>
          <li>
            20.5. As Partes reconhecem que a presente contratação ou qualquer
            condição presente nestes Termos de Uso e seus anexos não implica na
            caracterização da MULTIPLICAR como uma instituição financeira,
            tampouco seguradora. A MULTIPLICAR NÃO PRESTA NENHUM SERVIÇO
            BANCÁRIO OU DE CÂMBIO AO USUÁRIO, E TAMPOUCO QUALQUER OUTRA
            ATIVIDADE PRIVATIVA DE INSTITUIÇÕES FINANCEIRAS OU SEGURADORAS, OU
            QUAISQUER OUTRAS QUE SEJAM REGULADAS OU FISCALIZADAS PELO BANCO
            CENTRAL, COMISSÃO DE VALORES MOBILIÁRIOS (CVM) OU PELA
            SUPERINTENDÊNCIA DE SEGUROS PRIVADOS (SUSEP). As Partes reconhecem
            que a MULTIPLICAR apenas oferece um Serviço de pagamento por conta e
            ordem dos Usuários de acordo com as condições estabelecidas nestes
            Termos de Uso, sendo assim classificada como PRESTADOR DE SERVIÇOS
            DE REDE.
          </li>
          <li>
            20.6. Toda informação ou dado pessoal do Usuário é armazenado em
            servidores ou meios magnéticos de alta segurança. Salvo com relação
            às informações que são publicadas nos Sites, a MULTIPLICAR adotará
            todas as medidas possíveis para manter a confidencialidade e a
            segurança das informações sigilosas, porém não se responsabilizará
            por eventuais prejuízos que sejam decorrentes da divulgação de tais
            informações por parte de terceiros que utilizem as redes públicas ou
            a internet, subvertendo os sistemas de segurança para acessar as
            informações de Usuários.
          </li>
          <li>
            20.7. VOCÊ EXPRESSAMENTE AUTORIZA QUE SUAS INFORMAÇÕES E DADOS SEJAM
            COMPARTILHADOS PELA MULTIPLICAR COM AS DEMAIS EMPRESAS INTEGRANTES
            DO GRUPO ECONÔMICO, PARCEIROS COMERCIAIS, AUTORIDADES E PESSOAS
            FÍSICAS OU JURÍDICAS QUE ALEGUEM TER SIDO LESADAS POR VOCÊ.
          </li>
          <li>
            20.8. As Partes declaram ser contratantes independentes, sem nenhuma
            relação societária ou comercial além da estabelecida por meio dos
            presentes Termos de Uso, e cada uma delas manterá a outra livre e
            indene por qualquer reclamação oriunda das obrigações tributárias,
            trabalhistas ou previdenciárias que forem de sua responsabilidade.
            Nenhuma das Partes induzirá pessoa alguma em erro sobre seu caráter
            de parte contratual independente da outra, nem obrigará a outra
            parte frente a terceiros. O descumprimento desta obrigação por Você
            autorizará a MULTIPLICAR a inabilitar o seu cadastro e/ou a sua
            Conta, sem prejuízo das medidas judiciais ou extrajudiciais que
            julgar cabíveis.
          </li>
          <li>
            20.9. A MULTIPLICAR não garante a conclusão de nenhuma Transação
            realizada entre Usuários. A MULTIPLICAR não garante o acesso e uso
            continuado ou ininterrupto do Site e/ou Aplicativo, dos Serviços ou
            do Software. O sistema pode eventualmente não estar disponível
            devido a dificuldades técnicas ou falhas de Internet, interrupção ou
            queda do sistema, nos links ou por qualquer outra circunstância
            alheia a MULTIPLICAR ou a seus Parceiros comerciais. Você não poderá
            responsabilizar a MULTIPLICAR, ou exigir ressarcimento algum, em
            virtude de prejuízos resultantes das dificuldades mencionadas, assim
            como por qualquer outro tipo de dano oriundo do disposto nesta
            cláusula.
          </li>
          <li>
            20.10. A MULTIPLICAR poderá modificar a qualquer momento, sem aviso
            prévio, estes Termos de Uso e seus Anexos. A MULTIPLICAR notificará
            as mudanças a Você publicando uma versão atualizada destes Termos de
            uso e/ou Anexos no Site e/ou Aplicativo, explicitando a data da
            última modificação e por meio de comunicação enviada ao endereço de
            e-mail principal do Usuário (que optar pelo recebimento deste tipo
            de comunicação por e-mail no ato de sua adesão a estes Termos de
            Uso).
            <ol className="flex flex-col gap-2 pl-6">
              <li>
                20.10.1. A versão atualizada dos Termos e Condições passará a
                vigorar de acordo com os prazos previstos na legislação em
                vigor, ocasião em que Você deverá, caso não aceite as
                modificações inseridas, realizar o cancelamento do nosso
                vinculo, o qual considerar-se-á encerrado por meio do
                cancelamento do seu cadastro e/ou a sua respectiva Conta. Após
                este prazo, caso Você continue utilizando o Site e/ou
                Aplicativo, considerar-se-á que Você concordou com os novos
                Termos de Uso e seus Anexos e o presente documento continuará
                vinculando ambas as Partes. Os Termos de Uso e seus anexos
                modificados serão aplicáveis a toda Transação que se efetue
                posteriormente à comunicação a Você.
              </li>
            </ol>
          </li>
          <li>
            20.11. Você autoriza de forma irrevogável a MULTIPLICAR ou quem
            designemos, a gravar todas as suas operações e/ou telefonemas
            relativas aos serviços prestados pela MULTIPLICAR, e a utilizar
            essas gravações como meio probatório perante autoridades
            administrativas ou judiciais. Os registros emitidos pela MULTIPLICAR
            serão considerados prova suficiente do cumprimento das Transações,
            substituindo assim a necessidade de qualquer recibo e são plenamente
            oponíveis a Você.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-21">
        <p>
          <strong>[Cláusula 21ª] LEGISLAÇÃO E FORO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            21.1. Todos os itens destes Termos de Uso são regidos pelas leis
            vigentes na República Federativa do Brasil. Para todos os assuntos
            referentes à interpretação, ao cumprimento ou qualquer outro
            questionamento relacionado a estes Termos de Uso, as Partes
            concordam em se submeter ao Foro da Comarca de São Paulo - SP, com
            exceção de reclamações apresentadas por Usuários que se enquadrem no
            conceito legal de consumidores, que poderão submeter tais
            reclamações ao foro de seu domicílio.
          </li>
        </ol>
        <br />
      </div>

      <div id="clause-22">
        <p>
          <strong>[Cláusula 22ª] CANAIS DE ATENDIMENTO</strong>
        </p>
        <br />
        <ol className="flex flex-col gap-2 pl-6">
          <li>
            22.1. Você poderá entrar em contato conosco através dos canais de
            atendimento a seguir:
          </li>
          <li>
            22.2. <strong>Endereço para envio de correspondências</strong>: Todas as requisições,
            correspondências, notificações ou pedidos de informação deverão ser
            enviadas para o endereço R Funchal, Número 411, CONJ 51 CEP
            04.551-060, São Paulo – SP
          </li>
        </ol>
        <br />
      </div>
    </Container>
  );
}
