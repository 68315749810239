function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3306 21.4918C11.6998 21.8609 12.2983 21.8609 12.6675 21.4918L18.9712 15.1881C19.3403 14.8189 19.3403 14.2204 18.9712 13.8512C18.602 13.482 18.0034 13.482 17.6342 13.8512L12.9444 18.541L12.9444 3.17534C12.9444 2.65324 12.5211 2.22999 11.999 2.22999C11.4769 2.22999 11.0537 2.65324 11.0537 3.17534L11.0537 18.541L6.36387 13.8512C5.99469 13.482 5.39612 13.482 5.02694 13.8512C4.65775 14.2204 4.65775 14.8189 5.02694 15.1881L11.3306 21.4918Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
