import { cn } from "src/utils/classes";

export function Container({ className, ...props }) {
  return (
    <div
      className={cn(
        "mx-auto max-w-7xl px-4 py-6 md:px-8 md:py-16 lg:px-16 xl:py-28",
        className
      )}
      {...props}
    />
  );
}
