import { useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";

import HeadingSection from "src/components/ui/HeadingSection";
import ContaDigital from "src/assets/images/conta-digital.png";
import POS from "src/assets/images/pos.png";
import Cartao from "src/assets/images/cartao.png";
import ChevronLeft from "src/assets/icons/24/ChevronLeft";
import ChevronRight from "src/assets/icons/24/ChevronRight";
import { Container } from "src/components/ui/Container";
import { cn } from "src/utils/classes";

export default function Carousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);

  const checkCanScrollHandler = useCallback(() => {
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      checkCanScrollHandler();
    }
  }, [emblaApi, checkCanScrollHandler]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
      checkCanScrollHandler();
    }
  }, [emblaApi, checkCanScrollHandler]);

  return (
    <section id="features" className="bg-baas-purple-brand w-full">
      <Container className={"pb-8 md:pb-8 lg:pb-8 xl:pb-8"}>
        <div>
          <div className="overflow-hidden" ref={emblaRef}>
            <div className="flex">
              <div className="flex-none w-full min-w-0">
                <div className="flex flex-col lg:flex-row gap-10 items-center justify-between">
                  <div className="max-w-none lg:max-w-md space-y-6">
                    <HeadingSection
                      className={
                        "text-baas-orange-brand text-center lg:text-left"
                      }
                    >
                      Que tal ter uma conta digital segura e sem burocracias?
                    </HeadingSection>
                    <p className="text-white">
                      Aproveite todos os recursos de nossas contas PJ e PF.
                      Encontre a segurança e praticidade que você procura, além
                      de benefícios para você e toda a sua rede. Desfrute de
                      produtos financeiros pensados para seu ecossistema, como,
                      pagamentos e emissão de cobrança em lote, Pix e tudo isso
                      com a identidade visual da sua marca.
                    </p>
                  </div>
                  <div className="max-w-md lg:max-w-xl">
                    <img src={ContaDigital} alt="Conta digital" />
                  </div>
                </div>
              </div>
              <div className="flex-none w-full min-w-0">
                <div className="flex flex-col lg:flex-row gap-10 items-center justify-around">
                  <div className="max-w-xs lg:max-w-sm">
                    <img src={POS} alt="POS" />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="max-w-none lg:max-w-md space-y-6">
                      <HeadingSection
                        className={
                          "text-baas-orange-brand text-center lg:text-left"
                        }
                      >
                        Tenha o controle total de sua maquininha.
                      </HeadingSection>
                      <p className="text-white">
                        Junto com a gente você terá o poder de definir as taxas
                        de adquirência e uma política comercial diferenciada
                        para o seu ecossistema, garantindo vantagens que nenhum
                        outro pode oferecer. Além disso, você também poderá ter
                        toda sua rede utilizando maquininhas personalizadas com
                        a sua marca. Já imaginou?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-none w-full min-w-0">
                <div className="flex flex-col lg:flex-row gap-10 items-center justify-around">
                  <div className="max-w-none lg:max-w-md space-y-6">
                    <HeadingSection
                      className={
                        "text-baas-orange-brand text-center lg:text-left"
                      }
                    >
                      Um cartão com a cara da sua marca.
                    </HeadingSection>
                    <p className="text-white">
                      Tenha um cartão personalizado, que se adapta às
                      necessidades da sua base e ainda carrega a identidade da
                      visual da sua rede.
                    </p>
                  </div>
                  <div className="max-w-sm lg:max-w-lg">
                    <img src={Cartao} alt="Cartão" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-11 mt-10">
            <button
              className={cn(
                "p-1 bg-baas-orange-brand rounded-2xl text-white",
                !canScrollPrev && "bg-white text-baas-purple-brand"
              )}
              onClick={scrollPrev}
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
            <button
              className={cn(
                "p-1 bg-baas-orange-brand rounded-2xl text-white",
                !canScrollNext && "bg-white text-baas-purple-brand"
              )}
              onClick={scrollNext}
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
}
