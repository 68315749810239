function Icon({ className = "w-12 h-12" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.00098H40C42.2092 8.00098 44 9.79184 44 12.001V36.001C44 38.2102 42.2092 40.001 40 40.001H8C5.79086 40.001 4 38.2102 4 36.001V12.001C4 9.79184 5.79086 8.00098 8 8.00098ZM27.3 30.901L40 22.001V17.801L25.3 28.101C24.5182 28.6436 23.4818 28.6436 22.7 28.101L8 17.801V22.001L20.7 30.901C22.682 32.2864 25.318 32.2864 27.3 30.901Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
