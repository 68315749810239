import HeadingSection from "src/components/ui/HeadingSection";
import Cancelar from "src/assets/images/cancelar.png";
import { Container } from "src/components/ui/Container";

export default function CancelAccount() {
  return (
    <section id="diferencas" className="bg-white">
      <Container>
        <HeadingSection className="mb-6 lg:mb-10 ">
          Precisa encerrar sua conta?
        </HeadingSection>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-16 lg:gap-4">
          <div className="space-y-6 lg:max-w-lg">
            <p className="leading-6">
              O processo é muito simples! E você pode solicitar o encerramento
              de onde estiver, a qualquer momento, basta estar com seu
              <span className="font-semibold">
                aplicativo do banco instalado
              </span>{" "}
              e seguir o passo a passo:
            </p>
            <ol className="list-decimal leading-6 pl-5">
              <li>Acesse sua conta no app, utilizando CPF/CNPJ e senha;</li>
              <li>
                Confira se você ainda tem saldo em sua conta, pois para fazermos
                o encerramento,{" "}
                <span className="font-semibold">
                  é necessário que o saldo esteja zerado
                </span>
                ;
              </li>
              <li>
                Na tela inicial, acesse o menu{" "}
                <span className="font-semibold">"Meu perfil"</span>, clicando no
                avatar do lado esquerdo da tela (acima do “saldo disponível”);
              </li>
              <li>
                Clique em <span className="font-semibold">"Configurações"</span>
                ;
              </li>
              <li>
                Clique em{" "}
                <span className="font-semibold">"Encerrar conta"</span>;
              </li>
              <li>
                <span className="font-semibold">Confirme</span> que deseja
                encerrar sua conta.
              </li>
            </ol>
            <div className="block lg:hidden max-w-xl mx-auto">
              <img
                src={Cancelar}
                alt="Cancelamento de conta"
                className="max-h-[500px]"
              />
            </div>
            <p className="leading-6">
              Pronto! Agora{" "}
              <span className="font-semibold">
                sua solicitação será encaminhada para o nosso time de suporte
              </span>
              , que deverá{" "}
              <span className="font-semibold">
                entrar em contato nos próximos dias
              </span>
              , via WhatsApp, para confirmar sua solicitação e concluir o
              processo.
            </p>
            <p className="leading-6">
              Evite qualquer tipo de golpe. Em qualquer contato que receber de
              nossa central de atendimento, confira se o número que entrou em
              contato com você é nosso número oficial e preste atenção nos
              comportamentos e pedidos do atendente.
            </p>
            <p className="leading-6">
              <span className="font-semibold">Atenção!</span> Deixar sua conta
              sem movimentação não a encerra automaticamente. É necessário
              solicitar formalmente o encerramento para que a conta seja
              fechada.
            </p>
          </div>
          <div className="hidden lg:block max-w-md mx-auto self-center">
            <img src={Cancelar} alt="Cancelamento de conta" />
          </div>
        </div>
      </Container>
    </section>
  );
}
