function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icone/Extrato">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 4.75C5.5 4.061 6.061 3.5 6.75 3.5H17.75C18.439 3.5 19 4.061 19 4.75V18.75C19 19.439 18.439 20 17.75 20H6.75C6.061 20 5.5 19.439 5.5 18.75V4.75ZM6.75 21.5H17.75C19.267 21.5 20.5 20.267 20.5 18.75V4.75C20.5 3.233 19.267 2 17.75 2H6.75C5.233 2 4 3.233 4 4.75V18.75C4 20.267 5.233 21.5 6.75 21.5ZM14.25 8H16.25C16.664 8 17 7.664 17 7.25C17 6.836 16.664 6.5 16.25 6.5H14.25C13.836 6.5 13.5 6.836 13.5 7.25C13.5 7.664 13.836 8 14.25 8ZM16.25 11H14.25C13.836 11 13.5 10.664 13.5 10.25C13.5 9.836 13.836 9.5 14.25 9.5H16.25C16.664 9.5 17 9.836 17 10.25C17 10.664 16.664 11 16.25 11ZM8.25 15.5H16.25C16.664 15.5 17 15.164 17 14.75C17 14.336 16.664 14 16.25 14H8.25C7.836 14 7.5 14.336 7.5 14.75C7.5 15.164 7.836 15.5 8.25 15.5ZM16.25 18.5H8.25C7.836 18.5 7.5 18.164 7.5 17.75C7.5 17.336 7.836 17 8.25 17H16.25C16.664 17 17 17.336 17 17.75C17 18.164 16.664 18.5 16.25 18.5ZM9 5.77564V5.75C9 5.336 9.336 5 9.75 5C10.164 5 10.5 5.336 10.5 5.75V5.78988C10.9851 5.89351 11.4134 6.19641 11.6883 6.6424C11.9053 6.9954 11.7963 7.4574 11.4423 7.6744C11.0913 7.8924 10.6283 7.7804 10.4113 7.4284C10.3703 7.3624 10.2783 7.2504 10.1253 7.2504H9.3013C9.1353 7.2504 9.0003 7.3864 9.0003 7.5534C9.0003 7.6924 9.0933 7.8134 9.2283 7.8474L10.6353 8.2004C11.4393 8.4024 12.0003 9.1214 12.0003 9.9494C12.0003 10.8399 11.3501 11.582 10.5 11.7268V11.7505C10.5 12.1645 10.164 12.5005 9.75 12.5005C9.336 12.5005 9 12.1645 9 11.7505V11.713C8.51243 11.6092 8.08245 11.3049 7.8073 10.8554C7.5923 10.5014 7.7043 10.0404 8.0573 9.8244C8.4123 9.6074 8.8733 9.7204 9.0893 10.0734C9.1383 10.1554 9.2283 10.2524 9.3753 10.2524H10.1963C10.3633 10.2524 10.5003 10.1164 10.5003 9.9494C10.5003 9.8104 10.4053 9.6894 10.2703 9.6554L8.8643 9.3024C8.0613 9.1014 7.5003 8.3824 7.5003 7.5534C7.5003 6.66214 8.1499 5.91962 9 5.77564Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Icon;
