import { useState } from "react";
import { Link } from "react-router-dom";

import HeadingSection from "src/components/ui/HeadingSection";
import Contato from "src/assets/images/contato.png";
import Email from "src/assets/icons/24/Email";
import Circle from "src/assets/icons/24/Circle";
import EmailBig from "src/assets/icons/48/Email";
import WhatsApp from "src/assets/icons/48/WhatsApp";
import Phone from "src/assets/icons/48/Phone";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

export default function Contact() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [sendingRequest, setSendingRequest] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSendingRequest(true);

    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await fetch(
        "https://onboarding.baas2u.com/www/contato",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nome: fullname,
            email: email,
            mensagem: message,
          }),
        }
      );

      if (!response.ok) {
        setErrorMessage("Erro ao enviar contato, tente novamente mais tarde!");
      }

      setFullname("");
      setEmail("");
      setMessage("");

      setSuccessMessage("Contato enviado com sucesso!");
    } catch (error) {
      setErrorMessage("Erro ao enviar contato, tente novamente mais tarde!");
    } finally {
      setSendingRequest(false);
    }
  };

  return (
    <section id="contato" className=" bg-white">
      <Container className={"flex flex-col items-center justify-center"}>
        <div className="w-full flex items-center justify-between gap-20">
          <div className="max-w-full lg:max-w-lg w-full">
            <HeadingSection>Fale com a gente</HeadingSection>
            <p className="leading-7 mt-6">
              Quer saber mais, ou falar com nosso time comercial? Deixe seu
              e-mail e o motivo do contato.
            </p>
            <div className="lg:hidden max-w-lg mt-8 mx-auto">
              <img src={Contato} alt="Entre em contato" />
            </div>
            <form className="space-y-6 mt-8" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="fullname">Nome</label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    autoComplete="off"
                    className="focus:ring-baas-purple-brand focus:border-baas-purple-brand block w-full border-black text-black"
                    required
                    value={fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                    disabled={sendingRequest}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="email">E-mail</label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="focus:ring-baas-purple-brand focus:border-baas-purple-brand block w-full border-black text-black"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    disabled={sendingRequest}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="mensagem">Mensagem</label>
                <div className="mt-1">
                  <textarea
                    type="mensagem"
                    name="mensagem"
                    id="mensagem"
                    autoComplete="off"
                    placeholder="Escreva sua mensagem..."
                    className="focus:ring-baas-purple-brand focus:border-baas-purple-brand block w-full border-black text-black"
                    required
                    rows={4}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    disabled={sendingRequest}
                  />
                </div>
              </div>
              <div>
                <Button
                  type="submit"
                  disabled={sendingRequest}
                  className={"flex items-center gap-3 w-28 h-10"}
                >
                  {sendingRequest ? (
                    <Circle className="w-5 h-5 animate-spin" />
                  ) : (
                    <>
                      Enviar <Email className="w-5 h-5" />
                    </>
                  )}
                </Button>
              </div>
            </form>
            {errorMessage && (
              <div className="mt-6 text-sm font-medium text-baas-red">
                <span>{errorMessage}</span>
              </div>
            )}
            {successMessage && (
              <div className="mt-6 text-sm font-medium text-baas-green">
                <span>{successMessage}</span>
              </div>
            )}
          </div>
          <div className="hidden lg:block lg:max-w-md xl:max-w-xl">
            <img src={Contato} alt="Entre em contato" />
          </div>
        </div>
        <div className="grid grid-cols-3 text-baas-purple-brand w-full mt-16 md:mt-20 lg:mt-28">
          <div className="col-span-1 flex flex-col items-center md:items-start gap-0 justify-start md:gap-3 lg:gap-6">
            <EmailBig className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            <p className="pl-1.5 text-xl md:text-2xl lg:text-3xl font-bold leading-[38.40px]">
              E-mail
            </p>
            <Link
              to="mailto:falecom@baas2u.com"
              className="pl-1.5 underline leading-6 text-baas-orange-brand text-sm md:text-base"
            >
              falecom@baas2u.com
            </Link>
          </div>
          <div className="col-span-1 flex flex-col items-center md:items-start gap-0 justify-start md:gap-3 lg:gap-6">
            <WhatsApp className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            <p className="pl-1.5 text-xl md:text-2xl lg:text-3xl font-bold leading-[38.40px]">
              WhatsApp
            </p>
            <Link
              to="https://api.whatsapp.com/send?phone=551140402635"
              className="pl-1.5 underline leading-6 text-baas-orange-brand text-sm md:text-base"
              rel="noopener noreferrer"
              target="_blank"
            >
              (11) 40402635
            </Link>
          </div>
          <div className="col-span-1 flex flex-col items-center md:items-start gap-0 justify-start md:gap-3 lg:gap-6">
            <Phone className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
            <p className="pl-1.5 text-xl md:text-2xl lg:text-3xl font-bold leading-[38.40px]">
              Telefone
            </p>
            <span className="pl-1.5 underline leading-6 text-baas-orange-brand text-sm md:text-base">
              (11) 40402635
            </span>
          </div>
        </div>
      </Container>
    </section>
  );
}
