import Header from "src/components/ui/Header";
import Footer from "src/components/ui/Footer";

const Wrapper = ({ children }) => {
  return (
    <div className="min-h-screen min-w-full max-w-full">
      <Header />
      <main className="overflow-x-hidden mt-16">{children}</main>
      <Footer />
    </div>
  );
};

export default Wrapper;
