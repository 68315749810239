import { cn } from "src/utils/classes";

const HeadingSection = ({ children, className }) => {
  return (
    <h2
      className={cn(
        "text-2xl md:text-3xl lg:text-4xl font-bold leading-[36px] md:leading-[40px] lg:leading-[47.60px] text-baas-purple-brand",
        className
      )}
    >
      {children}
    </h2>
  );
};

export default HeadingSection;
