import HeadingSection from "src/components/ui/HeadingSection";
import ChevronRight from "src/assets/icons/24/ChevronRight";
import Entrada from "src/assets/icons/24/Entrada";
import Saida from "src/assets/icons/24/Saida";
import LogoBranco from "src/assets/logo/BaaSBranco";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

const Advantages = () => {
  return (
    <section id="vantagens" className="bg-baas-purple-brand w-full">
      <Container>
        <div className="flex flex-col items-center xl:grid grid-cols-3 gap-16 justify-between">
          <div className="col-span-1 flex flex-col items-center sm:items-start justify-between gap-6 max-w-xl">
            <div className="text-center sm:text-left space-y-3 xl:max-w-xs">
              <HeadingSection className="text-white">
                Com a BaaS2u, sua rede enxerga um horizonte inimaginável.
              </HeadingSection>
              <p className="text-white">
                Nós não só acabamos com as suas despesas financeiras, como
                também as transformamos em lucro para sua rede.
              </p>
            </div>
            <div className="flex flex-wrap md:flex-nowrap items-center gap-6">
              <Button
                color="white"
                href={"/produtos-financeiros"}
                className="whitespace-nowrap"
              >
                Entenda como
              </Button>
              <a
                href={"/#contato"}
                className="flex items-center gap-3 text-white px-5 whitespace-nowrap"
              >
                Fale com a gente <ChevronRight />
              </a>
            </div>
          </div>
          <div className="col-span-2 flex flex-col sm:flex-row justify-end gap-16">
            <div className="flex flex-col justify-between items-center gap-6 text-white sm:items-start">
              <div className="flex gap-2">
                <span className="text-3xl font-bold leading-[41.60px]">
                  Sem a
                </span>
                <LogoBranco className="w-32 h-auto" />
              </div>
              <div className="space-y-6 grow">
                <div className="flex flex-col text-sm leading-6">
                  <span className="font-bold">Segmento de banking</span>
                  <span>Pagamento de contas</span>
                  <span>Recebimento de vendas</span>
                  <span>Emissão de cobranças</span>
                </div>
                <div className="flex flex-col text-sm leading-6">
                  <span className="font-bold">Segmento de Acquiring</span>
                  <span>Vendas no cartão;</span>
                  <span>Antecipação de recebíveis.</span>
                </div>
              </div>

              <div className="flex items-center gap-6 font-bold">
                <p className="text-lg lg:text-xl leading-7">Custo financeiro</p>
                <span className="text-sm text-baas-red leading-[21px] py-4 px-3 rounded-lg border border-baas-red flex items-center gap-3">
                  <Entrada />
                  -8,5%
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center gap-6 text-white sm:items-start">
              <div className="flex gap-2">
                <span className="text-3xl font-bold leading-[41.60px]">
                  Com a
                </span>
                <LogoBranco className="w-32 h-auto" />
              </div>
              <div className="flex flex-col text-sm leading-6">
                <p className="font-bold">Monetização 360</p>
                <ul className="list-disc pl-5">
                  <li>MDR;</li>
                  <li>Antecipação;</li>
                  <li>Aluguel de POS;</li>
                  <li>Tarifa manutenção de conta;</li>
                  <li>Tarifa de transferências;</li>
                  <li>Tarifa de emissão de boletos;</li>
                  <li>Rebate de pagamento de boletos;</li>
                  <li>Intercâmbio de cartão de débito;</li>
                  <li>Float sob saldo aplicado.</li>
                </ul>
              </div>
              <div className="flex items-center gap-6 font-bold">
                <p className="text-lg lg:text-xl leading-7">
                  Receita financeira <br />
                  Take Rate
                </p>
                <span className="text-sm text-baas-green leading-[21px] py-4 px-3 rounded-lg border border-baas-green flex items-center gap-3">
                  <Saida />
                  3% a 5%
                </span>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Advantages;
