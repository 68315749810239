"use client";

import { useState } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/outline";

import Supermercado from "src/assets/images/supermercado.png";
import Restaurante from "src/assets/images/restaurante.png";
import Academia from "src/assets/images/academia.png";
import Farmacia from "src/assets/images/farmacia.png";
import Franquia from "src/assets/images/franquia.png";
import HeadingSection from "src/components/ui/HeadingSection";
import { Container } from "src/components/ui/Container";
import { cn } from "src/utils/classes";

const LABEL_SUPERMERCADO = "Cadeia de supermercados";
const LABEL_RESTAURANTE = "Rede de restaurantes";
const LABEL_ACADEMIA = "Rede de academias";
const LABEL_FARMACIA = "Rede de farmácias";
const LABEL_FRANQUIA = "Franquias";

export default function Benefits() {
  const [activeDisclosurePanel, setActiveDisclosurePanel] =
    useState(LABEL_SUPERMERCADO);

  const toggleDisclosurePanel = (label) =>
    setActiveDisclosurePanel((prev) => (prev === label ? prev : label));

  const getImage = () => {
    switch (activeDisclosurePanel) {
      case LABEL_SUPERMERCADO:
        return <img src={Supermercado} alt={activeDisclosurePanel} />;
      case LABEL_RESTAURANTE:
        return <img src={Restaurante} alt={activeDisclosurePanel} />;
      case LABEL_ACADEMIA:
        return <img src={Academia} alt={activeDisclosurePanel} />;
      case LABEL_FARMACIA:
        return <img src={Farmacia} alt={activeDisclosurePanel} />;
      case LABEL_FRANQUIA:
        return <img src={Franquia} alt={activeDisclosurePanel} />;

      default:
        return <img src={Supermercado} alt={activeDisclosurePanel} />;
    }
  };

  return (
    <section id="beneficios" className="bg-white">
      <Container
        className={
          "flex items-start flex-col lg:flex-row lg:items-center gap-14"
        }
      >
        <div className="max-w-xl lg:max-w-md space-y-8">
          <HeadingSection>
            Benefícios para ecossistemas de qualquer nicho.
          </HeadingSection>
          <BenefitsItem
            label={LABEL_SUPERMERCADO}
            list={[
              { description: "Descontos em faturas de fornecedores;" },
              { description: "Maior prazo de pagamento com fornecedores;" },
              { description: "Entre outros a se analisar." },
            ]}
            activeDisclosurePanel={activeDisclosurePanel}
            onToggle={toggleDisclosurePanel}
          />
          <BenefitsItem
            label={LABEL_RESTAURANTE}
            list={[
              {
                description:
                  "Pagamento de gorjetas diretamente para as contas dos garçons e garçonetes;",
              },
              { description: "Descontos em faturas de fornecedores;" },
              { description: "Maior prazo de pagamento com fornecedores;" },
              { description: "Entre outros a se analisar." },
            ]}
            activeDisclosurePanel={activeDisclosurePanel}
            onToggle={toggleDisclosurePanel}
          />
          <BenefitsItem
            label={LABEL_ACADEMIA}
            list={[
              { description: "Cobrança de recorrente;" },
              { description: "Benefícios exclusivos para personais;" },
              { description: "Entre outros a se analisar." },
            ]}
            activeDisclosurePanel={activeDisclosurePanel}
            onToggle={toggleDisclosurePanel}
          />
          <BenefitsItem
            label={LABEL_FARMACIA}
            list={[
              {
                description:
                  "Recebíveis como garantia na compra de medicamentos;",
              },
              { description: "Descontos em faturas de fornecedores;" },
              { description: "Maior prazo de pagamento com fornecedores;" },
              { description: "Entre outros a se analisar." },
            ]}
            activeDisclosurePanel={activeDisclosurePanel}
            onToggle={toggleDisclosurePanel}
          />
          <BenefitsItem
            label={LABEL_FRANQUIA}
            list={[
              { description: "Profit share nas transações;" },
              { description: "Redução da inadimplência;" },
              {
                description:
                  "Maior controle do negócio e visibilidade transacional por ecossistema;",
              },
              {
                description:
                  "Maior crédito e prazo na compra de insumos para os franqueados.",
              },
            ]}
            activeDisclosurePanel={activeDisclosurePanel}
            onToggle={toggleDisclosurePanel}
          />
        </div>
        <div className="max-w-lg lg:max-w-md xl:max-w-xl mx-auto lg:mx-0">
          {getImage()}
        </div>
      </Container>
    </section>
  );
}

const BenefitsItem = ({ label, list, activeDisclosurePanel, onToggle }) => {
  return (
    <div>
      <Disclosure>
        <DisclosureButton
          onClick={() => onToggle(label)}
          className={
            "flex items-center gap-3 text-2xl font-bold leading-[33.60px] text-baas-purple-brand border-l-2 border-baas-purple-brand pl-2 ml-4 lg:ml-0"
          }
        >
          {label}
          <ChevronRightIcon
            className={cn(
              "w-5 h-5 transform transition-transform",
              activeDisclosurePanel === label ? "rotate-90 " : "rotate-0"
            )}
          />
        </DisclosureButton>
        <Transition show={activeDisclosurePanel === label}>
          <DisclosurePanel>
            <ul className="list-disc pl-8 mt-3">
              {list?.map((item, index) => (
                <li key={index}>{item?.description}</li>
              ))}
            </ul>
          </DisclosurePanel>
        </Transition>
      </Disclosure>
    </div>
  );
};
