import Home from "src/assets/images/home.png";
import HomeMobile from "src/assets/images/home-mobile.png";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

const HeroSection = () => {
  return (
    <section className="w-full h-full bg-baas-purple-brand">
      <Container className="relative">
        <div className="static sm:absolute sm:top-1/3 sm:z-20 flex flex-col items-center sm:items-start gap-6">
          <h1 className="text-white text-center mt-6 text-2xl sm:text-left sm:mt-0 sm:text-3xl md:text-4xl lg:text-5xl font-bold">
            Construindo soluções <br />
            financeiras para <br />
            diversos mercados
          </h1>
          <div className="max-w-sm sm:hidden z-0">
            <img
              src={HomeMobile}
              alt="Solução BaaS"
              className="w-full h-auto"
            />
          </div>
          <div>
            <Button
              className={"w-auto"}
              variant="outline"
              color="white"
              href={"/produtos-financeiros/"}
            >
              Entenda como
            </Button>
          </div>
        </div>
        <div className="w-full h-full hidden sm:block z-0">
          <img src={Home} alt="Solução BaaS" className="w-full h-auto" />
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
