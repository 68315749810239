import Logo from "src/assets/logo/BaaS";
import { Container } from "src/components/ui/Container";
import { navigation } from "src/utils/footerNavigation";

const Footer = () => {
  const ListaTarifas = () => (
    <ul className="space-y-2 mt-[14px]">
      {navigation.fees.map((item) => (
        <li key={item.name}>
          <a
            href={item.href}
            target="_blank"
            rel="noreferrer"
            className="text-black text-sm leading-[21px] hover:underline whitespace-nowrap"
          >
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  );

  return (
    <footer
      className="bg-baas-grey text-white"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <Container className={"py-20"}>
        <div className="flex flex-col-reverse lg:flex-row items-start md:items-center justify-between gap-10 pb-20 border-b border-b-baas-orange-brand">
          <div className="max-w-xl md:max-w-xs mx-auto lg:mx-0 self-start">
            <Logo className="w-full h-auto" />
          </div>
          <div className="w-full md:w-auto grid grid-cols-2 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 gap-10">
            <div className="col-span-1">
              <ul className="space-y-4">
                {navigation.company.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="text-black hover:underline leading-6 font-semibold"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-1">
              <p className="text-baas-purple-brand leading-6 font-semibold">
                Termos e políticas
              </p>
              <ul className="space-y-2 mt-[14px]">
                {navigation.legal.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className="text-black text-sm leading-[21px] hover:underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="hidden lg:block xl:hidden mt-[14px]">
                <p className="text-baas-purple-brand leading-6 font-semibold">
                  Tarifas
                </p>
                <ListaTarifas />
              </div>
            </div>
            <div className="col-span-1 block lg:hidden xl:block">
              <p className="text-baas-purple-brand leading-6 font-semibold">
                Tarifas
              </p>
              <ListaTarifas />
            </div>
            <div className="col-span-1">
              <p className="text-baas-purple-brand leading-6 font-semibold">
                Siga a gente!
              </p>
              <ul className="space-y-2 mt-[14px]">
                {navigation.social.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noreferrer"
                      className="text-black text-sm leading-[21px] hover:underline whitespace-nowrap flex items-center gap-3"
                    >
                      <item.icon />
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-8 flex flex-wrap items-start justify-between text-black gap-10">
          <div className="text-sm leading-[21px] flex flex-col">
            <span className="font-bold text-baas-purple-brand">
              BaaS2U Instituição de Pagamento SA
            </span>
            <span>
              44.391.784/0001-00 Rua Funchal, 411 - Vila Olimpia - São Paulo
            </span>
          </div>
          <div className="flex flex-wrap items-start gap-10 whitespace-nowrap">
            <div className="flex flex-col text-sm">
              <span className="font-semibold text-baas-purple-brand leading-6">
                Contatos para clientes:
              </span>
              <span className="leading-[21px]">(11) 4040 2635</span>
            </div>
            <div className="flex flex-col text-sm">
              <span className="font-semibold text-baas-purple-brand leading-6">
                Contatos de relacionamento:
              </span>
              <span className="leading-[21px]">
                4004 5705 (Capitais e Regiões Metropolitanas)
              </span>
              <span className="leading-[21px]">
                0800 729 1705 (Demais Regiões)
              </span>
            </div>
            <div className="flex flex-col text-sm">
              <span className="font-semibold text-baas-purple-brand leading-6">
                SAC
              </span>
              <span className="leading-[21px]">0800 729 1706</span>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
