import Wrapper from "src/components/ui/Wrapper";

import TermsAndConditions from "src/components/pages/Terms/TermsAndConditions";

const Terms = () => {
  return (
    <Wrapper>
      <TermsAndConditions />
    </Wrapper>
  );
};

export default Terms;
