function Icon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 118 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1237 17.1402C24.7829 16.7537 25.3279 16.2487 25.7525 15.6316C26.3483 14.7619 26.6493 13.7458 26.6493 12.6143C26.6493 10.5508 25.8983 8.95805 24.4184 7.87957C22.9766 6.82913 20.8503 6.29613 18.106 6.29613H10V28.7355H18.9553C21.5347 28.7137 23.5597 28.137 24.9762 27.0149C26.418 25.8741 27.1468 24.1815 27.1468 21.984C27.1468 20.6842 26.7919 19.5652 26.0916 18.6582C25.5751 17.988 24.916 17.48 24.1206 17.1402H24.1237ZM15.3079 19.2909H19.0852C20.9675 19.319 21.8421 20.1575 21.8421 21.9373C21.8421 22.7134 21.5918 23.2994 21.0753 23.7296C20.5492 24.1691 19.7887 24.3935 18.8158 24.3935H15.3079V19.2909ZM18.2866 15.3822H15.3079V10.6662H18.106C19.2658 10.6662 20.1151 10.8594 20.6253 11.2366C21.1101 11.5982 21.3446 12.1935 21.3446 13.06C21.3446 14.0325 21.0277 15.3323 18.2898 15.3791L18.2866 15.3822Z"
        fill="#4B479D"
      />
      <path
        d="M43.5332 24.6336V17.6484C43.5332 15.7345 42.8899 14.2228 41.6192 13.1536C40.3675 12.1001 38.6658 11.5671 36.5617 11.5671C35.1832 11.5671 33.9188 11.804 32.8065 12.2746C31.6784 12.7515 30.7879 13.4217 30.1637 14.2695C29.5331 15.1267 29.213 16.0711 29.213 17.0811V17.4582H34.3529V17.0811C34.3529 16.5886 34.5146 16.2145 34.8441 15.9371C35.1895 15.6503 35.6902 15.5039 36.3303 15.5039C37.0465 15.5039 37.5694 15.6846 37.8799 16.0431C38.2063 16.4171 38.3711 16.9377 38.3711 17.586V18.1346H36.7391C34.2357 18.144 32.2837 18.6333 30.9369 19.5903C29.5521 20.5752 28.8486 21.9997 28.8486 23.8263C28.8486 25.3224 29.4253 26.5817 30.5598 27.5636C31.6816 28.5361 33.1076 29.0286 34.8029 29.0286C36.343 29.0286 37.6518 28.5704 38.707 27.6633C38.7957 27.9875 38.9066 28.2742 39.0366 28.5267L39.1443 28.7324H44.1828V28.0093L44.1385 27.9251C43.7455 27.1989 43.5427 26.0892 43.5332 24.6305V24.6336ZM37.3697 24.6055C36.8817 24.858 36.343 24.9858 35.7599 24.9858C35.1769 24.9858 34.7586 24.8486 34.4448 24.565C34.1438 24.2907 33.9949 23.9509 33.9917 23.527L34.0044 23.2933C34.0487 22.8195 34.1977 21.4075 37.1225 21.4075H38.3743V23.7016C38.1461 24.0787 37.8165 24.3749 37.3666 24.6086L37.3697 24.6055Z"
        fill="#4B479D"
      />
      <path
        d="M60.16 24.6336V17.6484C60.16 15.7346 59.5167 14.2228 58.246 13.1537C56.9943 12.1001 55.2926 11.5671 53.1884 11.5671C51.81 11.5671 50.5456 11.804 49.4333 12.2747C48.3052 12.7516 47.4147 13.4217 46.7904 14.2696C46.1598 15.1267 45.8398 16.0712 45.8398 17.0811V17.4583H50.9797V17.0811C50.9797 16.5886 51.1413 16.2146 51.4709 15.9372C51.8163 15.6504 52.317 15.5039 52.9571 15.5039C53.6733 15.5039 54.1961 15.6847 54.5067 16.0431C54.8331 16.4172 54.9979 16.9377 54.9979 17.5861V18.1347H53.3659C50.8625 18.144 48.9104 18.6334 47.5636 19.5903C46.1757 20.5753 45.4754 21.9997 45.4754 23.8263C45.4754 25.3225 46.0521 26.5817 47.1866 27.5636C48.3083 28.5361 49.7343 29.0286 51.4297 29.0286C52.9698 29.0286 54.2785 28.5704 55.3338 27.6633C55.4225 27.9875 55.5334 28.2743 55.6633 28.5268L55.7711 28.7325H60.8096V28.0093L60.7652 27.9252C60.3723 27.1989 60.1695 26.0893 60.16 24.6305V24.6336ZM53.9965 24.6056C53.5085 24.858 52.9698 24.9858 52.3867 24.9858C51.8036 24.9858 51.3853 24.8487 51.0716 24.565C50.7706 24.2907 50.6216 23.951 50.6185 23.5271L50.6311 23.2933C50.6755 22.8195 50.8244 21.4075 53.7493 21.4075H55.001V23.7016C54.7729 24.0788 54.4433 24.3749 53.9933 24.6087L53.9965 24.6056Z"
        fill="#4B479D"
      />
      <path
        d="M79.8013 22.6386C79.8013 20.8526 79.1675 19.3408 77.9126 18.1376C76.6926 16.9688 74.7406 15.9807 72.1072 15.2014C70.7478 14.7962 69.7084 14.3629 69.0207 13.9079C68.406 13.5026 68.1081 13.0257 68.1081 12.4491C68.1081 11.8132 68.3616 11.3145 68.8813 10.9249C69.4263 10.5165 70.2186 10.3108 71.239 10.3108C72.2593 10.3108 73.1054 10.5571 73.6663 11.0433C74.2209 11.5233 74.4902 12.1904 74.4902 13.0787V13.4559H79.7981V13.0787C79.7981 11.7104 79.4337 10.4667 78.7144 9.38819C77.995 8.3097 76.9778 7.46499 75.6849 6.87588C74.4078 6.293 72.9375 6 71.315 6C69.6925 6 68.2348 6.27118 66.9356 6.80419C65.6173 7.34655 64.5906 8.11333 63.8808 9.08272C63.1646 10.0615 62.8034 11.1898 62.8034 12.4366C62.8034 14.8305 64.1343 16.7506 66.7613 18.1439C67.6961 18.6395 68.9764 19.1475 70.564 19.6525C72.0914 20.1388 73.153 20.6001 73.7202 21.024C74.2304 21.4043 74.4776 21.9435 74.4776 22.6667C74.4776 23.3181 74.2336 23.8106 73.7265 24.1722C73.191 24.5556 72.4146 24.7488 71.4228 24.7488C70.0665 24.7488 69.0493 24.4776 68.3965 23.9384C67.7563 23.4116 67.4426 22.623 67.4426 21.5352V21.158H62.1189V21.5352C62.1189 23.0064 62.5118 24.3187 63.2819 25.4408C64.0519 26.5567 65.1769 27.445 66.6314 28.084C68.0669 28.7136 69.6799 29.0316 71.4196 29.0316C73.9357 29.0316 75.9733 28.4674 77.4785 27.3546C79.0154 26.2169 79.7949 24.6304 79.7949 22.6386H79.8013Z"
        fill="#4B479D"
      />
      <path
        d="M94.004 16.364C94.004 14.5125 92.3087 14.1415 91.3992 14.1415C88.6676 14.1415 82.4851 17.0435 82.4851 20.2852C82.4851 20.9647 82.7355 21.5507 83.2393 22.0774C83.5847 22.4795 84.2122 22.4484 84.5893 22.1086C84.9664 21.7377 84.9664 21.1517 84.621 20.7808C84.4942 20.6561 84.3706 20.5034 84.3706 20.2883C84.3706 18.7454 88.8578 15.9962 91.4024 15.9962C91.9031 15.9962 92.1249 16.152 92.1249 16.3671C92.1249 16.551 92.0298 16.9531 91.7478 17.5391C90.3345 20.3195 83.0524 27.9748 75.9604 32.2981C75.5516 32.5444 75.428 33.0088 75.5833 33.4109C75.7417 33.7818 76.0555 34.0592 76.6195 33.9657C78.9106 33.5948 84.152 32.8841 86.8202 32.8841C87.3525 32.8841 87.7613 32.482 87.7613 31.9584C87.7613 31.4347 87.3525 31.0326 86.8202 31.0326C85.0931 31.0326 82.6467 31.31 80.0102 31.681C81.3919 30.7864 82.4281 29.951 83.496 29.0876C88.8007 24.6739 94.0104 19.054 94.0104 16.3671L94.004 16.364Z"
        fill="#F47A20"
      />
      <path
        d="M107.849 28.4954C107.599 28.0621 107.003 27.9405 106.562 28.218C103.958 29.8544 99.781 32.0768 97.8353 32.0768L101.445 24.0505C101.632 23.6172 101.476 23.0936 101.036 22.8785C100.595 22.6323 100.063 22.785 99.781 23.1871C99.7493 23.2494 97.1128 27.4169 92.1218 31.4939C92.5306 30.1973 94.0992 26.9244 95.6076 24.116C95.8579 23.6827 95.7026 23.0967 95.2305 22.8816C94.7583 22.6354 94.1942 22.7881 93.9439 23.2526C92.3119 26.4631 90.0208 30.724 90.0208 32.5475C90.0208 33.3517 90.493 34 91.3074 34C92.0616 34 92.971 33.2582 94.2259 32.1797C95.3572 31.2227 96.3903 30.1411 97.3663 28.9068L96.235 31.0981C96.048 31.5002 95.953 31.9023 95.953 32.2701C95.953 33.1646 96.6438 34 97.6483 34C100.503 34 105.967 30.8518 107.567 29.7702C108.007 29.4928 108.131 28.938 107.849 28.5047V28.4954Z"
        fill="#F47A20"
      />
    </svg>
  );
}

export default Icon;
