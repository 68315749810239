import HeadingSection from "src/components/ui/HeadingSection";
import Implantacao from "src/assets/images/tempo-implantacao.png";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

const Implantation = () => {
  return (
    <section id="implantacao" className="bg-white">
      <Container
        className={
          "flex flex-col-reverse items-start lg:flex-row lg:items-center gap-16 justify-around"
        }
      >
        <div className="max-w-xl mx-auto">
          <img src={Implantacao} alt="Ilustração do tempo de implantação" />
        </div>
        <div className={"space-y-6 lg:max-w-md"}>
          <HeadingSection className={"lg:max-w-xs"}>
            Implantação em tempo record.
          </HeadingSection>
          <p className="leading-6">
            Oferecemos um sistema eficiente, com estrutura pronta de ponta a
            ponta num prazo de implantação de menos de 1 semana e profit share
            com o cliente.
          </p>
          <Button variant="outline" href={"/#contato"}>
            Fale com a gente
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Implantation;
