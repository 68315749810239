import Facebook from "src/assets/icons/24/Facebook";
import Instagram from "src/assets/icons/24/Instagram";
import Linkedin from "src/assets/icons/24/Linkedin";

export const navigation = {
  company: [
    { name: "Home", href: "/" },
    { name: "Produtos financeiros", href: "/produtos-financeiros" },
    { name: "Sobre nós", href: "/#sobre-nos" },
    { name: "Fale com a gente", href: "/#contato" },
    // { name: "FAQ", href: "#" },
  ],
  legal: [
    {
      name: "Termos e condições de uso",
      href: "/termos-e-condicoes",
    },
    {
      name: "Abertura de conta PF",
      href: "condicoes_abertura_pf.pdf",
    },
    {
      name: "Abertura de conta PJ",
      href: "condicoes_abertura_pj.pdf",
    },
    {
      name: "Contrato cartão pré-pago",
      href: "contrato_cartao_pre_pago.pdf",
    },
    {
      name: "Política de LGPD",
      href: "Política de LGPD.pdf",
    },
    {
      name: "Política de PLD",
      href: "Política de PLD.pdf",
    },
    {
      name: "Política de privacidade",
      href: "politica_de_privacidade.pdf",
    },
    {
      name: "Código de ética e conduta",
      href: "Código de Ética e Conduta.pdf",
    },
  ],
  fees: [
    {
      name: "Pessoa física",
      href: "/tarifas_pf.pdf",
    },
    {
      name: "Pessoa jurídica",
      href: "/tarifas_pj.pdf",
    },
  ],
  social: [
    {
      name: "Facebook",
      href: "https://www.facebook.com/Baas2u/",
      icon: Facebook,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/multiplicarbaas2u/",
      icon: Instagram,
    },
    {
      name: "Linkedin",
      href: "https://br.linkedin.com/company/multiplicar-investimentos",
      icon: Linkedin,
    },
    // {
    //   name: "Youtube",
    //   href: "#",
    //   icon: Youtube,
    // },
  ],
};
