import { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Transition,
  TransitionChild,
  Popover,
  PopoverPanel,
  PopoverButton,
  PopoverBackdrop,
} from "@headlessui/react";

import { cn } from "src/utils/classes";
import { navigation } from "src/utils/header";
import { Button } from "src/components/ui/Button";

const MobileNavigation = () => {
  function MobileNavIcon({ open }) {
    return (
      <svg
        aria-hidden="true"
        className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
        fill="none"
        strokeWidth={2}
        strokeLinecap="round"
      >
        <path
          d="M0 1H14M0 7H14M0 13H14"
          className={cn(
            "origin-center transition",
            open && "scale-90 opacity-0"
          )}
        />
        <path
          d="M2 2L12 12M12 2L2 12"
          className={cn(
            "origin-center transition",
            !open && "scale-90 opacity-0"
          )}
        />
      </svg>
    );
  }

  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </PopoverButton>
      <Transition>
        <TransitionChild
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <PopoverBackdrop className="fixed inset-0 bg-slate-300/50" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <PopoverPanel
            as="div"
            className="w-48 absolute right-0 top-full mt-2 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            {({ close }) => (
              <>
                {navigation.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    className="mt-1 py-1.5 flex gap-2 w-full rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={close}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                ))}
                <hr className="m-2 border-slate-300/40" />
                <Button
                  className={"text-sm p-1 mt-1 h-auto"}
                  href={"/#contato"}
                  variant="outline"
                  onClick={close}
                >
                  Fale com a gente
                </Button>
                <Button
                  className={"text-sm p-1 mt-1.5 h-auto"}
                  href={"https://multiplicar.baas2u.com/"}
                  target="_blank"
                  onClick={close}
                >
                  Internet Banking
                </Button>
              </>
            )}
          </PopoverPanel>
        </TransitionChild>
      </Transition>
    </Popover>
  );
};

export default MobileNavigation;
