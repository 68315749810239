import HeadingSection from "src/components/ui/HeadingSection";
import Diferencas from "src/assets/images/diferencas.png";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

export default function Differences() {
  return (
    <section id="diferencas" className="bg-white">
      <Container>
        <div className="flex flex-col lg:flex-row items-start lg:items-end justify-between gap-16 lg:gap-4">
          <div className={"space-y-6 lg:space-y-10"}>
            <HeadingSection className="max-w-md">
              E o que nos diferencia nesse mercado?
            </HeadingSection>
            <p className="leading-6 font-semibold">
              Nossos principais diferenciais
            </p>
            <div className="flex flex-wrap gap-6">
              <ul className="list-disc leading-6 pl-5">
                <li>Tecnologia proprietária;</li>
                <li>Escalabilidade;</li>
                <li>Monetização360;</li>
                <li>Velocidade de implantação;</li>
              </ul>
              <ul className="list-disc leading-6 pl-5">
                <li>Ativações customizadas ao modelo de negócio;</li>
                <li>Aderência a qualquer segmento do mercado;</li>
                <li>Modelo de negócio: Profit share;</li>
                <li>Relacionamento próximo e humanizado.</li>
              </ul>
            </div>
          </div>
          <div className="max-w-xl lg:max-w-md mx-auto flex flex-col items-center gap-6">
            <img src={Diferencas} alt="Ilustração das diferenças" />
            <Button variant="outline" className={"w-fit"} href={"/#contato"}>
              Fale com a gente
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}
