function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.03972 13.0662C8.82004 12.8465 8.82004 12.4904 9.03972 12.2707L14.7746 6.53584C14.9943 6.31618 15.3504 6.31618 15.57 6.53584L15.8352 6.80101C16.0549 7.02068 16.0549 7.37683 15.8352 7.59651L10.7633 12.6684L15.8352 17.7404C16.0549 17.9601 16.0549 18.3162 15.8352 18.5358L15.57 18.801C15.3504 19.0207 14.9943 19.0207 14.7746 18.801L9.03972 13.0662Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
