import Wrapper from "src/components/ui/Wrapper";

import CancelAccount from "src/components/pages/Help/CancelAccount";

const Help = () => {
  return (
    <Wrapper>
      <CancelAccount />
    </Wrapper>
  );
};

export default Help;
