import Wrapper from "src/components/ui/Wrapper";

import Structure from "src/components/pages/FinancialProducts/Structure";
import Carousel from "src/components/pages/FinancialProducts/Carousel";

const FinancialProducts = () => {
  return (
    <Wrapper>
      <Structure />
      <Carousel />
    </Wrapper>
  );
};

export default FinancialProducts;
