import { Link } from "react-router-dom";

import HeadingSection from "src/components/ui/HeadingSection";
import Multiplicar from "src/assets/clients/multiplicar.svg";
import Smart from "src/assets/clients/smart.svg";
import JD from "src/assets/clients/jd.svg";
import Versa from "src/assets/clients/versa.svg";
import Oficina from "src/assets/clients/oficina.svg";
import Rp3 from "src/assets/clients/rp3.svg";
import { Container } from "src/components/ui/Container";

export default function Clients() {
  return (
    <section id="clientes" className="bg-baas-purple-brand">
      <Container
        className={
          "flex flex-col lg:flex-row gap-14 lg:gap-20 items-center justify-between"
        }
      >
        <div className="text-white max-w-full md:max-w-md text-center lg:text-left">
          <HeadingSection className="text-white lg:text-3xl xl:text-4xl">
            Resultados que causam impacto.
          </HeadingSection>
          <p className="leading-7 mt-6">
            Transformamos ecossistemas financeiros inteiros, gerando impacto e
            criando valor para cada usuário de nossos serviços.
          </p>
          <div className="mt-10 grid grid-cols-2 lg:flex items-start lg:gap-16">
            <div className="flex flex-col">
              <span className="text-4xl font-bold leading-[57.60px] text-baas-orange-brand">
                6
              </span>
              <span className="text-sm leading-6">
                Ecossistemas <br />
                financeiros
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-4xl font-bold leading-[57.60px] text-baas-orange-brand">
                300k+
              </span>
              <span className="text-sm leading-6">Contas abertas</span>
            </div>
          </div>
          <div className="flex flex-col mt-10 ">
            <span className="text-sm leading-6">Mais de</span>
            <span className="text-4xl font-bold leading-[57.60px] text-baas-orange-brand">
              R$250.000.000
            </span>
            <span className="text-sm leading-6">
              Transacionados recorrentemente
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center py-16 px-9 text-white">
          <p className="leading-7 font-bold text-sm">
            Conheça nossos ecossistemas financeiros
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-10 mt-10">
            <Link
              to={"https://multiplicar.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Multiplicar}
                className="col-span-1 max-w-[170px]"
                alt="Logo Multiplicar"
              />
            </Link>
            <Link
              to={"https://smart.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Smart}
                className="col-span-1 max-w-[170px]"
                alt="Logo Smart"
              />
            </Link>
            <Link
              to={"https://jd.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={JD}
                className="col-span-1 max-w-[170px]"
                alt="Logo JD"
              />
            </Link>
            <Link
              to={"https://versa.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Versa}
                className="col-span-1 max-w-[170px]"
                alt="Logo Versa"
              />
            </Link>
            <Link
              to={"https://oficina.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Oficina}
                className="col-span-1 max-w-[170px]"
                alt="Logo Oficina"
              />
            </Link>
            <Link
              to={"https://rp3.baas2u.com/"}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={Rp3}
                className="col-span-1 max-w-[170px]"
                alt="Logo Rp3"
              />
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
}
