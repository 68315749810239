import { HashLink } from "react-router-hash-link";

import { cn } from "src/utils/classes";

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-lg py-2 px-4 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 leading-6",
  outline:
    "group inline-flex ring-1 items-center justify-center rounded-lg py-2 px-4 focus:outline-none leading-6",
};

const variantStyles = {
  solid: {
    purple:
      "bg-baas-main-600 text-white hover:bg-baas-main-700 hover:text-slate-100 active:bg-baas-main-600 active:text-slate-300 focus-visible:outline-baas-main-600",
    white:
      "bg-white text-baas-main-600 hover:bg-slate-50 active:bg-white focus-visible:outline-white",
  },
  outline: {
    purple:
      "ring-baas-main-600 text-baas-main-600 hover:bg-slate-100 hover:ring-baas-main-700 active:bg-slate-100 active:text-slate-600 focus-visible:outline-baas-main-600 focus-visible:ring-slate-700",
    white: "ring-white text-white focus-visible:outline-white",
  },
};

export function Button({
  variant = "solid",
  color = "purple",
  className,
  href,
  children,
  ...props
}) {
  className = cn(baseStyles[variant], variantStyles[variant][color], className);

  return href ? (
    <HashLink to={href} className={className} {...props}>
      {children}
    </HashLink>
  ) : (
    <button className={className} {...props}>
      {children}
    </button>
  );
}
