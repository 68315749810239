function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.00049H20C21.1046 4.00049 22 4.89592 22 6.00049V18.0005C22 19.1051 21.1046 20.0005 20 20.0005H4C2.89543 20.0005 2 19.1051 2 18.0005V6.00049C2 4.89592 2.89543 4.00049 4 4.00049ZM13.65 15.4505L20 11.0005V8.90049L12.65 14.0505C12.2591 14.3218 11.7409 14.3218 11.35 14.0505L4 8.90049V11.0005L10.35 15.4505C11.341 16.1432 12.659 16.1432 13.65 15.4505Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
