import HeadingSection from "src/components/ui/HeadingSection";
import StructureImg from "src/assets/images/structure.png";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";

export default function Structure() {
  return (
    <section id="estrutura" className="bg-baas-grey">
      <Container className={"flex flex-col items-center gap-16 md:gap-24"}>
        <div
          className={
            "flex flex-col lg:flex-row items-center justify-between gap-8 lg:gap-24"
          }
        >
          <HeadingSection className="whitespace-nowrap">
            Estrutura de ponta a ponta
          </HeadingSection>
          <p className="leading-7 max-w-2xl lg:max-w-xl">
            Os serviços disponíveis no mercado na criação de um BaaS pode exigir
            que contrate diversas soluções para concatenar todos os serviços
            necessários. Mas com a BaaS2u é diferente, aqui você tem uma
            estrutura completa.
          </p>
        </div>
        <div className="flex flex-col gap-6 md:flex-row items-center md:gap-20">
          <div className="max-w-xl md:max-w-2xl">
            <img src={StructureImg} alt="Estrutura BaaS" />
          </div>
          <Button
            variant="outline"
            className={"w-fit h-fit whitespace-nowrap"}
            href={"/#contato"}
          >
            Fale com a gente
          </Button>
        </div>
      </Container>
    </section>
  );
}
