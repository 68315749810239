function Icon({ className = "w-6 h-6" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9603 13.0662C15.18 12.8465 15.18 12.4904 14.9603 12.2707L9.22541 6.53584C9.00573 6.31618 8.64963 6.31618 8.42996 6.53584L8.16476 6.80101C7.94508 7.02068 7.94508 7.37683 8.16476 7.59651L13.2367 12.6684L8.16476 17.7404C7.94508 17.9601 7.94508 18.3162 8.16476 18.5358L8.42995 18.801C8.64963 19.0207 9.00573 19.0207 9.22541 18.801L14.9603 13.0662Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Icon;
