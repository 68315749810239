import { Link } from "react-router-dom";

import Logo from "src/assets/logo/BaaS";
import MobileNavigation from "src/components/ui/MobileNavigation";
import { ActiveLink } from "src/components/ui/ActiveLink";
import { Container } from "src/components/ui/Container";
import { Button } from "src/components/ui/Button";
import { navigation } from "src/utils/header";

const Header = () => {
  return (
    <header className="fixed top-0 w-full z-50 bg-white border border-b border-b-black h-16">
      <Container className={"py-3 md:py-3 lg:py-3 xl:py-3"}>
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full lg:w-auto">
              <Link to="/">
                <span className="sr-only">Multiplicar BaaS</span>
                <Logo className="w-28 h-auto" />
              </Link>
              <div className="mr-2 flex items-center lg:hidden">
                <MobileNavigation />
              </div>
            </div>
            <div className="hidden lg:block ml-auto mr-8 md:space-x-8">
              {navigation.map((item) => (
                <ActiveLink key={item.name} href={item.href} text={item.name} />
              ))}
            </div>
          </div>
          <div className="hidden lg:flex items-center gap-3 text-right">
            <Button href={"/#contato"} variant="outline">
              Fale com a gente
            </Button>
            <Button href={"https://multiplicar.baas2u.com/"} target="_blank">
              Acessar Internet Banking
            </Button>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Header;
