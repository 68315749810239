function Icon({ className = "w-12 h-12" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / Phone">
        <path
          id="Vector"
          d="M34.6667 42.0006C31.3594 41.9976 28.1033 41.1802 25.1868 39.6206L24.2868 39.1206C18.0683 35.7772 12.9701 30.679 9.62675 24.4606L9.12675 23.5606C7.52639 20.6274 6.68101 17.3419 6.66675 14.0005V12.6605C6.66591 11.5945 7.09067 10.5721 7.84675 9.82053L11.2267 6.44053C11.555 6.10973 12.0184 5.9503 12.4807 6.00906C12.943 6.06782 13.3517 6.33814 13.5867 6.74054L18.0867 14.4605C18.5418 15.2463 18.4104 16.2401 17.7667 16.8805L13.9867 20.6606C13.6728 20.971 13.5997 21.4506 13.8067 21.8406L14.5067 23.1606C17.0208 27.8174 20.8454 31.635 25.5068 34.1406L26.8267 34.8606C27.2167 35.0676 27.6964 34.9944 28.0068 34.6806L31.7868 30.9006C32.4272 30.257 33.421 30.1256 34.2068 30.5806L41.9267 35.0806C42.3291 35.3156 42.5995 35.7244 42.6581 36.1866C42.7169 36.649 42.5575 37.1124 42.2267 37.4406L38.8467 40.8206C38.0951 41.5766 37.0728 42.0014 36.0068 42.0006H34.6667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Icon;
